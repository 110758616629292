import { Button, Container, List, ListItem, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Conversation } from '../../Types/conversation';
import { AppEnum } from '../../Types/enums';
import { useAppProvider } from '../Providers/AppProvider';
import { useFeatures } from '../Providers/FeatureProvider';
import ChatCard from './ChatCard';

const NewChatButton = styled(Button)({
  margin: '8px 16px',
}) as typeof Button;

const NewChatButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const CenteredContentList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export type ChatHistoryProps = {
  conversations: Conversation[];
  handleConversationUpdate: (conversationId?: string, fetchConversations?: boolean) => void;
  appEnum: AppEnum;
};

const ChatHistory = ({
  conversations,
  handleConversationUpdate,
  appEnum,
}: ChatHistoryProps): JSX.Element => {
  const navigate = useNavigate();
  const { setCurrentApp } = useAppProvider();
  const features = useFeatures();
  const [selectedChat, setSelectedChat] = useState(AppEnum.IMAGPT);

  const handleNewChat = async (appName: AppEnum = AppEnum.IMAGPT) => {
    setSelectedChat(appName);
    setCurrentApp(appName);
    navigate('/chat');
    // So default chat info shows up after clicking new chat
    handleConversationUpdate(undefined, false);
  };

  useEffect(() => {
    setSelectedChat(appEnum);
  }, [appEnum]);

  return (
    <Container
      style={{ overflowY: 'auto', padding: '0px', backgroundColor: '#fff', height: 'inherit' }}
    >
      <CenteredContentList>
        <NewChatButtonContainer>
          <NewChatButton
            variant="contained"
            sx={{ bgcolor: selectedChat === AppEnum.IMAGPT ? '#00b5e3' : 'white' }}
            onClick={() => handleNewChat()}
          >
            IMAGPT
          </NewChatButton>
          {features.producer_playbook && (
            <NewChatButton
              variant="contained"
              sx={{ bgcolor: selectedChat === AppEnum.PRODUCER_PLAYBOOK ? '#00b5e3' : 'white' }}
              onClick={() => handleNewChat(AppEnum.PRODUCER_PLAYBOOK)}
            >
              AskIMA - Producer Playbook
            </NewChatButton>
          )}
        </NewChatButtonContainer>
        {conversations.map((conversation) => (
          <ListItem key={conversation.id}>
            <ChatCard
              conversation={conversation}
              onConversationCardClick={handleConversationUpdate}
            />
          </ListItem>
        ))}
      </CenteredContentList>
    </Container>
  );
};

export default ChatHistory;
