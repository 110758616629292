import { Checkbox, Divider, FormControlLabel, styled, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';

import { Tag } from '../../Types/enums';
import { useFeatures } from '../Providers/FeatureProvider';

const FiltersContainer = styled('div')({
  overflowY: 'auto',
  padding: '0px',
  backgroundColor: '#fff',
  height: 'inherit',
  userSelect: 'none',
});

const TitleRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: '15px',
  paddingLeft: '24px',
});

const CheckboxOuterContainer = styled('div')({
  paddingRight: '15px',
  paddingLeft: '24px',
});

const CheckboxContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '20px',
  marginTop: '5px',
});

const IndividualCheckboxContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

type TagCounts = {
  [key in Tag]: number;
};

export type PromptFiltersProps = {
  tagOptions: TagCounts;
  handleTagUpdate: (event: ChangeEvent<HTMLInputElement>, tag: Tag) => void;
};

type GroupName = 'General';
type TagGroupings = {
  [key in GroupName]: string[];
};

const TAG_GROUPINGS: TagGroupings = {
  General: ['PRODUCERPLAYBOOK', 'IMAGPT'],
};

const PromptFilters = ({ tagOptions, handleTagUpdate }: PromptFiltersProps): JSX.Element => {
  const features = useFeatures();

  return (
    <FiltersContainer id="filter-container">
      <TitleRow>
        <Typography
          variant="h5"
          sx={{
            fontSize: '16px',
            fontWeight: '700',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          Filters
        </Typography>
      </TitleRow>
      <Divider sx={{ width: '100%', marginBottom: '20px' }} />
      {Object.keys(TAG_GROUPINGS).map((group: string, index: number) => (
        <CheckboxOuterContainer key={index}>
          <Typography variant="h6" sx={{ fontWeight: '500', fontSize: '16px' }}>
            {group}
          </Typography>
          <CheckboxContainer>
            {(() => {
              const total = TAG_GROUPINGS[group as GroupName].reduce((acc, tag) => {
                const tagName = Tag[tag as keyof typeof Tag];
                return acc + tagOptions[tagName];
              }, 0);

              if (total > 0) {
                return TAG_GROUPINGS[group as GroupName].map((tag, index2: number) => {
                  const tagName = Tag[tag as keyof typeof Tag];
                  const tagCount = tagOptions[tagName];

                  if (tagName === 'Producer Playbook' && !features.producer_playbook) {
                    return null;
                  }

                  return tagCount > 0 ? (
                    <IndividualCheckboxContainer
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        fontWeight: '500',
                      }}
                      key={index2}
                    >
                      <FormControlLabel
                        key={index2}
                        control={<Checkbox onChange={(event) => handleTagUpdate(event, tagName)} />}
                        label={`${tagName}`}
                        sx={{
                          userSelect: 'none',
                          width: '85%',
                          display: 'flex',
                          alignItems: 'flex-start',
                          span: { display: 'flex', alignItems: 'flex-start', paddingTop: '0px' },
                        }}
                      />
                      <Typography style={{ float: 'right', color: 'grey', fontWeight: '500' }}>
                        {tagOptions[tagName]}
                      </Typography>
                    </IndividualCheckboxContainer>
                  ) : null;
                });
              } else {
                return (
                  <Typography
                    key={index}
                    variant="h6"
                    sx={{ fontWeight: '500', fontSize: '16px', color: 'grey' }}
                  >
                    No {group} prompts
                  </Typography>
                );
              }
            })()}
          </CheckboxContainer>
        </CheckboxOuterContainer>
      ))}
    </FiltersContainer>
  );
};

export default PromptFilters;
