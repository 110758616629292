const intervals = [
  { type: 'year', secondsThreshold: 31536000 },
  { type: 'month', secondsThreshold: 2592000 },
  { type: 'day', secondsThreshold: 86400 },
  { type: 'hour', secondsThreshold: 3600 },
  { type: 'minute', secondsThreshold: 60 },
  { type: 'second', secondsThreshold: 1 },
];

function timeSince(date: Date) {
  let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  if (seconds <= 0) {
    seconds = 1;
  }

  let intervalType;
  let interval = -1;

  for (const { type, secondsThreshold } of intervals) {
    if (seconds >= secondsThreshold) {
      intervalType = type;
      interval = Math.floor(seconds / secondsThreshold);
      break;
    }
  }
  return `${interval} ${intervalType}${interval > 1 ? 's' : ''} ago`;
}

export default timeSince;
