import { AppConfigurationClient } from '@azure/app-configuration';
import { Client } from '@microsoft/microsoft-graph-client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getAzureGraphToken } from '../../services/api';
import { UserRole } from '../../Types/enums';
import { GuidedSteps } from '../../Types/guides';
import { useAuth } from './AuthProvider';
import { useJoyride } from './JoyrideProvider';

type Flags = { [name: string]: boolean };

export const FeatureContext = createContext<Flags | null>(null);
const client = new AppConfigurationClient(import.meta.env.VITE_AZURE_APP_CONFIG_CONNECTION_STRING);

const defaultFlags = {
  producer_playbook: false,
  gpt4: false,
  can_override: false,
  show_functions: false,
} as Flags;

interface Props {
  children: React.ReactNode;
}

export const FeatureProvider: React.FC<Props> = ({ children }) => {
  const { currentUser } = useAuth();
  const [flags, setFlags] = useState<Flags>(defaultFlags);
  const [initialized, setInitialized] = useState(false);
  const { tourState, setTourState } = useJoyride();
  const [searchParams] = useSearchParams();

  const [checkOverrides, setCheckOverrides] = useState(false);

  useEffect(() => {
    const getGroupEmails = async (group: string): Promise<boolean> => {
      const client = Client.init({
        authProvider: async (done) => {
          const token = await getAzureGraphToken();
          done(null, token);
        },
      });

      try {
        const membersRes = await client.api(`/groups/${group}/members`).version('v1.0').get();
        const groupMemberEmails = membersRes.value.map(
          (member: { mail: string }) => member.mail.toLowerCase
        );

        return groupMemberEmails.includes(currentUser?.email.toLowerCase);
      } catch (err) {
        console.error(err);
        return false;
      }
    };

    async function fetchFeatureFlags() {
      try {
        const tempFlags = { ...defaultFlags };
        const flagPromises = Object.keys(tempFlags).map(async (flag: string) => {
          const result = await client.getConfigurationSetting({
            key: `.appconfig.featureflag/${flag.toString().trim()}`,
          });

          if (result && result.value) {
            const resultValue = JSON.parse(result.value);
            tempFlags[flag] = resultValue.enabled;

            if (resultValue.conditions.client_filters.length > 0) {
              if (
                resultValue.conditions.client_filters[0].parameters.Audience.Users.map(
                  (user: string) => user.toLowerCase()
                ).includes(currentUser?.email.toLowerCase()) ||
                (resultValue.conditions.client_filters[0].parameters.Audience.Groups.length > 0 &&
                  (await getGroupEmails(
                    resultValue.conditions.client_filters[0].parameters.Audience.Groups[0].Name
                  )))
              ) {
                tempFlags[flag] = true;
              }

              if (resultValue.conditions.client_filters[0].parameters.Audience.Exclusion) {
                if (
                  resultValue.conditions.client_filters[0].parameters.Audience.Exclusion.Users.includes(
                    currentUser?.email
                  )
                ) {
                  tempFlags[flag] = false;
                }
              }
            }
          }
        });

        await Promise.all(flagPromises); // Wait for all promises to resolve

        setFlags(tempFlags);
        setInitialized(true);

        if (tempFlags.can_override || currentUser?.role === UserRole.ADMIN) {
          setCheckOverrides(true);
        }

        if (!currentUser?.metadata?.tourCompleted) {
          setTourState({ ...tourState, ...{ steps: GuidedSteps } });
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (!initialized && currentUser) {
      fetchFeatureFlags();
    }
  }, [initialized, currentUser, setTourState, tourState]);

  useEffect(() => {
    if (checkOverrides) {
      const currentFlags = { ...flags };

      Object.keys(currentFlags).forEach((key) => {
        const value = searchParams.get(key);
        if (value === 'true') {
          currentFlags[key] = true;
        } else if (value === 'false') {
          currentFlags[key] = false;
        }
      });

      setFlags(currentFlags);
      setCheckOverrides(false);
    }
  }, [searchParams, checkOverrides, flags]);

  return <FeatureContext.Provider value={flags}>{children}</FeatureContext.Provider>;
};

export const useFeatures = (): Flags => {
  const featureContext = useContext(FeatureContext);
  if (!featureContext) {
    throw new Error('useFeature must be used within a FeatureProvider');
  }
  return featureContext;
};
