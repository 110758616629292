import { ArrowUpward } from '@mui/icons-material';
import { Container, Fab, Link, styled, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

const FAQPageDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '20px',
  paddingRight: '200px',
  paddingLeft: '200px',
});

const QuestionTypeTitle = styled(Typography)({
  variant: 'subtitle1',
  fontWeight: 'bold',
  marginBottom: '20px',
});

const QuestionLinkContainer = styled(Typography)({
  variant: 'subtitle2',
  marginBottom: '25px',

  a: {
    color: 'black',
  },
});

const StyledFab = styled(Fab)({
  position: 'fixed',
  bottom: '20px',
  float: 'right',
  width: '56px',
});

interface QuestionAnswer {
  question: string;
  answer: string;
}
const FAQList = {
  Capabilities: [
    {
      question: 'What is the difference between the “Chat” and “Prompts” main menu options?',
      answer: `The "Chat" page will lead you to a chat interface that will allow you to resume or start a new chat. The "Prompts" page will lead you to a library of pre-built prompts available for use.`,
    },
    {
      question: 'What can the Ask IMA tool help me with?',
      answer:
        'Ask IMA can help you with a wide range of tasks, including providing information on industry trends, answering technical questions, offering project ideas, and assisting with data analysis.',
    },
    {
      question: 'How does Ask IMA handle sensitive or confidential information?',
      answer: `AskIMA was created with security in mind. Any confidential or sensitive information will remain within IMA and not be shared.`,
    },
    {
      question: 'What is the accuracy of the responses provided by Ask IMA?',
      answer: `AskIMA - Producer Playbook is designed to be as accurate as possible in the information it provides you from the Producer Playbook. However, if you're unsure of any information provided to you, it is best practice
        to check citations.\n IMAGPT is a ChatGPT-like clone designed to be as accurate as possible, however, it is possible to get IMAGPT to hallucinate answers with leading questions or changing settings(Temperature) to make
        it more creative. It is always best practice to double check any information.`,
    },
    {
      question: 'Can AskIMA handle multiple languages?',
      answer: `AskIMA can handle multiple languages for general tasks, however it is recommended to use English when possible for the best experience.`,
    },
    {
      question: 'Is AskIMA connected to any other apps?',
      answer: `No, AskIMA is a standalone tool and is not connected to any other apps.`,
    },
    {
      question: 'Can AskIMA provide information about our clients?',
      answer:
        'No. It can provide general information about companies based on public data scraped from the internet on or before April 2023, but it will not have detailed client information built in unless it is in the producer playbook. However, you can use AskIMA to feed in client information to work with.',
    },
    {
      question: `Can I add prompts to prompt library?`,
      answer: `Yes! Anyone can publish their prompts to the prompt library.IMA reserves the right to modify or delete any published prompts.`,
    },
    {
      question: `Does switching between modes/models affect only my current chat/prompt or all chats/prompts?`,
      answer: `You cannot change between modes in the middle of a chat. Creating a new conversation will open up the chat with your mode of choice. If you're unsure what mode a chat is using, look for the "P" symbol to indicate Producer Playbook, and no symbol will appear for IMAGPT.`,
    },
    {
      question: `Are there any significant changes in the usage or application of these modes?`,
      answer: `IMAGPT is a general purpose model meant to answer general questions. AskIMA - Producer Playbook is specifically meant to be used to ask information about the Producer Playbook and does not provide general answers.`,
    },
    {
      question: `Can AskIMA provide project-specific recommendations?`,
      answer: `Yes, AskIMA can provide project-specific recommendations based on the information you provide. However, it is important to note that the recommendations provided should be validated and reviewed before use or sharing.`,
    },
    {
      question: `How can I ensure that AskIMA's responses align with our company's messaging and branding guidelines?`,
      answer: `AskIMA is a tool that can assist in maintaining IMA's voice and messaging. However, details of the tone of voice, messaging, and audience type must be given in prompts to ensure consistency. Always review communications before sending anything out.`,
    },
    {
      question: `Is there a way to share AskIMA's responses with other team members or departments?`,
      answer: `Yes, in a couple of ways. The first is to simply download a conversation in markdown (text) and share the markdown file. The second way is to share the prompt that was used. Simply go to the prompt of your choosing and click the three dots to bring up a menu. Choose 'Copy' and you will be given an option to copy a link to that prompt.`,
    },
    {
      question: `Can AskIMA handle complex or technical questions about our products or services or insurance products in general?`,
      answer: `Yes, AskIMA can handle complex or technical questions about products, services, and insurance products as long the information needed to answer the question is contained within the Producer Playbook or given to AskIMA in the chat.`,
    },
    {
      question: `Can AskIMA provide insights or suggestions based on historical data or past interactions?`,
      answer: `Yes, however, AskIMA is only able to reference individual chat histories. To reference a past interaction, it must be in the same chat as the question.`,
    },
    {
      question: `Can AskIMA help with prospect data research or data enrichment?`,
      answer: `IMAGPT was trained on public internet from April 2023 and before. It is possible IMAGPT will be able to surface public information for data, however, it will be much more useful in giving suggestions or advice on how to enrich data.`,
    },
    {
      question: `Can AskIMA provide data or insights on market trends or industry news?`,
      answer: `No, AskIMA has date cutoffs for data and will not include recent market trends or news. However, if given news or market trend data manually in the chat, it can make point out observations or trends within that data.`,
    },
    {
      question: `Are there any limitations to the types of questions the virtual assistant can answer?`,
      answer: `There are no hard limitations on what you can ask, however, AskIMA may give no or hallucinated answers if not enough information or data is provided. AskIMA is also better at certain tasks than others so quality of responses may vary.`,
    },
    {
      question: `Is AskIMA able to assist with scheduling meetings or calls with clients or create tasks?`,
      answer: `AskIMA is a standalone product and does not interface with any other apps. However, AskIMA can still be used to suggest meeting times, can surface important information while on a call with a client, and create example tasks from data entered into the chat.`,
    },
    {
      question: `Can I change the format of an output? i.e. bullet points, checklist, etc`,
      answer: `Yes! Simply tell AskIMA what format you would like your output to be in.`,
    },
  ],
  Usage: [
    {
      question: `Is it okay to put client or IMA data in AskIMA?`,
      answer: `Yes. AskIMA is sanctioned by IMA for use with IMA or client data.`,
    },
    {
      question: 'Why is Ask IMA taking a long time to respond?',
      answer: `AskIMA's response time will correlate with the length of it's response. As a general rule of thumb, if you ask a complex or long question, AskIMA will take longer to respond.`,
    },
    {
      question: `Do I have to disclose that I used AskIMA when using its outputs?`,
      answer:
        'Yes. Under the company guidelines, you should disclose if you used any generative AI tools to produce work.',
    },
    {
      question: 'Is there a limit to the number of questions I can ask Ask IMA?',
      answer: `The limit is based on tokens at 128k tokens per chat. While not an exact parallel, you can think of tokens like a character limit. The limit is very high for the average user, but can reach a maximum if you use a single chat to perform a complex task. A good rule of thumb is if you change topics, simply create a new chat.`,
    },
    {
      question: `What is the size limit of documents that can be copied in?`,
      answer: `The limit is based on tokens at 128k tokens per chat. While not an exact parallel, you can think of tokens like a character limit.  A good rule of thumb is that 1 word is equivalent 1.33 tokens, so 128k tokens is roughly equivalent to a 96k words, or about a 192 page document.`,
    },
    {
      question: `How does AskIMA stay up-to-date with changes in our products, services, or industry?`,
      answer: `IMAGPT is trained on general internet data from April 2023 and earlier. AskIMA - Producer Playbook is based off of the 2023 version of the Producer Playbook and associated data as of February 6th, 2024 which is made clear in all messages containing information from the Producer Playbook. Updates can be made to the Producer Playbook and associated materials on the backend. Aside from updating the Producer Playbook, AskIMA does not stay up to date, but can still comment on recent data or events if given the information.`,
    },
    {
      question: `How can I customize AskIMA to be more customized for my needs ?`,
      answer: `There is no customization available within AskIMA. If there are certain tasks or prompts used frequently, creating a custom prompt in the prompt library for quick access and repeatability is encouraged.`,
    },
  ],
  'Administration and Support': [
    {
      question: 'How do I report a bug or issue with Ask IMA?',
      answer: `To report a bug or issue with Ask IMA, please select the "itsupport@imacorp.com" link at the bottom of this page to start an email. This will notify IMA IT for support.`,
    },
    {
      question: 'Who can access Ask IMA tool within our organization?',
      answer: `AskIMA is accessible through your Okta portal and IMAGPT can be accessed by all employees. Only certain users will have access to the AskIMA - Producer Playbook functionality.`,
    },
    {
      question: `Who should I contact if I have additional questions or need support with AskIMA?`,
      answer: `You can contact Brent Brown (Brent.Brown@imacorp.com) or Rebecca Green (Rebecca.Green@imacorp.com) with additional questions or to request support.`,
    },
    {
      question: `How do I request a new feature or enhancement for AskIMA?`,
      answer: `You can contact Brent Brown (Brent.Brown@imacorp.com) or Rebecca Green (Rebecca.Green@imacorp.com) to request new features or enhancements. `,
    },
  ],
};

const FAQ = (): JSX.Element => {
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;

    const handleScrollEvent = () => {
      if (scrollableDiv) {
        setIsAtTop(scrollableDiv.scrollTop === 0);
      }
    };

    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScrollEvent);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScrollEvent);
      }
    };
  }, []);

  const handleScroll = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        height: '100%',
        scrollBehavior: 'smooth',
      }}
      ref={scrollableDivRef}
    >
      <Container
        sx={{ position: 'relative', bgcolor: 'white', height: 'fit-content', margin: '0px' }}
      >
        <FAQPageDiv>
          <Typography variant="h4" style={{ fontWeight: '700', fontSize: '36px' }}>
            Frequently Asked Questions
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <div style={{ width: '45%' }}>
              <QuestionTypeTitle>Capabilities</QuestionTypeTitle>
              {FAQList['Capabilities'].map((questionAnswer: QuestionAnswer, index: number) => {
                return (
                  <QuestionLinkContainer key={index}>
                    <a
                      href={`#c${index}`}
                      style={{
                        marginBottom: '30px',
                      }}
                    >
                      {questionAnswer.question}
                    </a>
                  </QuestionLinkContainer>
                );
              })}
            </div>

            <div style={{ width: '45%' }}>
              <QuestionTypeTitle>Usage</QuestionTypeTitle>
              {FAQList['Usage'].map((questionAnswer: QuestionAnswer, index: number) => {
                return (
                  <QuestionLinkContainer key={index}>
                    <a
                      href={`#u${index}`}
                      style={{
                        marginBottom: '30px',
                      }}
                    >
                      {questionAnswer.question}
                    </a>
                  </QuestionLinkContainer>
                );
              })}
              <QuestionTypeTitle>Administration and Support</QuestionTypeTitle>
              {FAQList['Administration and Support'].map(
                (questionAnswer: QuestionAnswer, index: number) => {
                  return (
                    <QuestionLinkContainer key={index}>
                      <a
                        href={`#a${index}`}
                        style={{
                          marginBottom: '30px',
                        }}
                      >
                        {questionAnswer.question}
                      </a>
                    </QuestionLinkContainer>
                  );
                }
              )}
            </div>
          </div>

          <br />
          <br />
          <Typography sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px' }}>
            Capabilities
          </Typography>
          {FAQList['Capabilities'].map((questionAnswer: QuestionAnswer, index: number) => {
            return (
              <div id={`c${index}`} key={index}>
                <Typography sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                  {questionAnswer.question}
                </Typography>
                <Typography style={{ marginBottom: '30px' }}>{questionAnswer.answer}</Typography>
              </div>
            );
          })}
          <Typography
            sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px', marginTop: '20px' }}
          >
            Usage
          </Typography>
          {FAQList['Usage'].map((questionAnswer: QuestionAnswer, index: number) => {
            return (
              <div id={`u${index}`} key={index}>
                <Typography sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                  {questionAnswer.question}
                </Typography>
                <Typography style={{ marginBottom: '30px' }}>{questionAnswer.answer}</Typography>
              </div>
            );
          })}
          <Typography
            sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px', marginTop: '20px' }}
          >
            Administration and Support
          </Typography>
          {FAQList['Administration and Support'].map(
            (questionAnswer: QuestionAnswer, index: number) => {
              return (
                <div id={`a${index}`} key={index}>
                  <Typography sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                    {questionAnswer.question}
                  </Typography>
                  <Typography style={{ marginBottom: '30px' }}>{questionAnswer.answer}</Typography>
                </div>
              );
            }
          )}
          <Typography
            sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px', marginTop: '20px' }}
          >
            Report an issue
          </Typography>
          <Typography marginBottom={'15px'}>
            To submit any app issues, feedback, or potential improvements, email:
          </Typography>
          <Typography>
            <span style={{ cursor: 'pointer' }}>
              <Link
                href={`mailto:itsupport@imacorp.com?subject=AskIMA Error`}
                style={{ color: '#003665' }}
              >
                itsupport@imacorp.com
              </Link>
              <br></br>
            </span>
          </Typography>
        </FAQPageDiv>
        {!isAtTop && (
          <div
            style={{
              position: 'absolute',
              right: '0',
              bottom: '20px',
              paddingRight: '76px',
            }}
          >
            <StyledFab onClick={handleScroll}>
              <ArrowUpward />
            </StyledFab>
          </div>
        )}
      </Container>
    </div>
  );
};

export default FAQ;
