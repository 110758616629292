import { Attachment, Mic, Send } from '@mui/icons-material';
import {
  Alert,
  Container,
  Drawer,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSound } from 'use-sound';

import mic_ding from '../assets/mic_ding.mp3';
import ChatBubbleComponent from '../components/Chat/ChatBubble';
import ChatHistory from '../components/Chat/ChatHistory';
import ChatInfo from '../components/Chat/ChatInfo';
import FileCard from '../components/Chat/FileCard';
import FunctionSettings from '../components/Chat/FunctionSettings';
import LimitWarning from '../components/Chat/LimitWarning';
import DrawerToggleButton from '../components/DrawerToggleButton';
import { useAppProvider } from '../components/Providers/AppProvider';
import { useAuth } from '../components/Providers/AuthProvider';
import { useFeatures } from '../components/Providers/FeatureProvider';
import { MessageFrom } from '../enums';
import {
  getConversation,
  getConversations,
  getMessages,
  getStreamingResponse,
  postConversation,
  postFile,
  // postMessage,
  updateConversation,
} from '../services/conversation';
import { findPrompt } from '../services/prompts';
import { speechToTextContinuous } from '../services/speech';
import { Conversation, Message, MessageFile } from '../Types/conversation';
import { FunctionName } from '../Types/enums';
import { Prompt } from '../Types/prompt';

export const IMAGE_MIME_TYPE = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/webp'];

const ChatPageContainer = styled('div')({
  display: 'flex',
  height: 'inherit',

  div: {
    position: 'relative',
  },
});

type ContainerProps = {
  open: boolean;
};

const drawerWidth = '350px';

const DrawerContainer = styled('div')<ContainerProps>(({ open }) => ({
  width: open ? drawerWidth : 'auto',
  zIndex: '1',
}));

const MainContainer = styled('div')<ContainerProps>(({ open }) => ({
  marginLeft: open ? 'auto' : `-${drawerWidth}`,
  width: `100%`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 1,
  alignItems: 'flex-start',
  height: 'inherit',
}));

const StyledChatContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'space-between',
  minHeight: '100%',

  '& > *:not(:last-child)': {
    textAlign: 'center',
  },
});

const StyledAlert = styled(Alert)({
  marginBottom: '10px',
  marginTop: '15px',
  backgroundColor: '#DEDEDE',
  color: 'black',
  '.MuiAlert-icon': { display: 'flex', alignItems: 'center' },
});

const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const ChatRow = styled('div')({
  display: 'flex',
  gap: '5px',
  marginBottom: '16px',
  alignItems: 'flex-end',
  position: 'sticky',
  bottom: '0',
  background: '#fff',
  paddingTop: '10px',
});

const SendButton = styled(IconButton)({
  height: '40px',
  width: '40px',
});

const StyledMic = styled(Mic)({
  color: 'red',
});

export type ChatProps = {
  llm: string;
};

const Chat = ({ llm }: ChatProps): JSX.Element => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState<Message[]>([]);
  const [shouldFetchMessages, setShouldFetchMessages] = useState<boolean>(false);
  const [shouldFetchConversations, setShouldFetchConversations] = useState<boolean>(true);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState<boolean>(false);
  const { conversationId } = useParams();
  const [currentConversationId, setCurrentConversationId] = useState<string>();
  const [currentConversation, setCurrentConversation] = useState<Conversation>();
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const [promptId, setPromptId] = useState<string>();
  const [prompt, setPrompt] = useState<Prompt>();
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width: 800px)').matches
  );
  const [functions, setFunctions] = useState<FunctionName[]>([]);
  const [useFunctions, setUseFunctions] = useState<boolean>(false);
  const [tokens, setTokens] = useState<number>(0);
  const [maxTokens, setMaxTokens] = useState<number>(16000);
  const [showChatInfo, setShowChatInfo] = useState<boolean>(false);
  const [greyAllMessages, setGreyAllMessages] = useState<boolean>(false);
  const [micActive, setMicActive] = useState<boolean>(false);
  const [playMicDing] = useSound(mic_ding, { volume: 0.5 });
  const { appState } = useAppProvider();
  const features = useFeatures();
  const { currentUser } = useAuth();
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<MessageFile[]>([]);

  const handleFileDelete = (index: number) => {
    setUploadedFiles((uploadedFiles) => {
      return [...uploadedFiles.slice(0, index), ...uploadedFiles.slice(index + 1)];
    });
  };

  const handleTextChange = (index: number, newText: string) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = { ...newFiles[index], contents: newText };
      return newFiles;
    });
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      enqueueSnackbar('No file selected.', { variant: 'error', autoHideDuration: 3000 });
      return;
    }

    const file = e.target.files[0];

    if (IMAGE_MIME_TYPE.includes(file.type)) {
      enqueueSnackbar('Image files are not supported.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    // Clear target value to allow the same filename
    // to be uploaded in succession.
    e.target.value = '';

    // Validate file size before uploading to server.
    // const fileSizeMB = file.size / 1024 ** 2;
    // if (fileSizeMB > 200) {
    //   enqueueSnackbar('Upload Failed. File size too large. Max size: 200MB.', {
    //     variant: 'error',
    //     autoHideDuration: 3000,
    //   });
    //   return;
    // }

    const index = filesToUpload.length;
    setFilesToUpload((filesToUpload) => [...filesToUpload, file]);

    (async () => {
      const formData = new FormData();
      formData.append('file', file);
      try {
        // const fileId: string = uuidv4();
        // analytics.fileUpload('started', { fileId, fileSize: fileSizeMB });
        // console.log('formData', formData);
        const messageFile = await postFile(formData);
        // analytics.fileUpload('ended', { fileId, fileSize: fileSizeMB });

        if (messageFile.statusText) {
          setFilesToUpload((filesToUpload) => [
            ...filesToUpload.slice(0, index),
            ...filesToUpload.slice(index + 1),
          ]);
          enqueueSnackbar('Network timeout error, or file is empty, corrupted, or encrypted', {
            variant: 'error',
            autoHideDuration: null,
          });
          throw Error(messageFile.statusText);
        }

        if (messageFile.contents === '') {
          handleFileDelete(index);
        }

        setUploadedFiles((uploadedFiles) => [...uploadedFiles, messageFile]);

        setFilesToUpload((filesToUpload) => [
          ...filesToUpload.slice(0, index),
          ...filesToUpload.slice(index + 1),
        ]);
      } catch (error) {
        handleFileDelete(index);
      }
    })();
  };

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;

      if (width < 1200) {
        setHistoryDrawerOpen(false);
      } else {
        setHistoryDrawerOpen(true);
      }
    }
    window.addEventListener('resize', handleResize);
  });

  // These three functions are hacks to get around costly rerenders for this page
  // this monolithic page should be refactored into smaller pieces
  const setChatPrompt = (prompt: string) => {
    if (inputRef.current) {
      inputRef.current.value = prompt;
    }
  };

  const getChatPrompt = () => {
    if (inputRef.current) {
      return inputRef.current.value;
    }
    return '';
  };

  const handleStreamingResponse = useCallback(
    async (response: Response) => {
      if (response && response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const initBotMessage = {
          conversationId: currentConversation?.id ?? '',
          from: MessageFrom.AI,
          message: '',
        } as Message;

        setMessages((messages) => [...messages, initBotMessage]);
        setUploadedFiles([]);

        let done, value;

        let result = '';

        do {
          ({ done, value } = await reader.read());

          if (done) {
            break;
          }

          const chunk = decoder.decode(value, { stream: true });
          const doneIndex = chunk.indexOf('NEW CONVERSATION ID:');

          if (doneIndex >= 0) {
            const newId = chunk.slice(doneIndex + 21);

            const newConversation = await getConversation(newId);
            setCurrentConversation(newConversation);

            setIsLoadingResponse(false);
            if (window.location.pathname === '/chat') {
              setShouldFetchConversations(true);
            }
            setShouldFetchMessages(true);
            navigate(`/chat/${newId}`);
          } else {
            result += chunk;
            setMessages((messages) => {
              const newMessages = [...messages];
              newMessages[newMessages.length - 1] = {
                ...newMessages[newMessages.length - 1],
                message: result,
              };
              return newMessages;
            });
          }
        } while (!done);
      }
    },
    [currentConversation?.id, navigate, setShouldFetchConversations, setIsLoadingResponse]
  );

  const handleSubmit = useCallback(
    async (prompt?: Prompt, input?: string) => {
      if (input && conversationId) {
        const message = input;
        setIsLoadingResponse(true);

        setGreyAllMessages(true);

        setMessages([
          ...messages,
          {
            conversationId,
            message,
            from: MessageFrom.USER,
            createdAt: '',
            id: '',
            deleted: false,
            tokens: 0,
            files: [],
          },
        ]);

        // if (appState.selectedApp !== AppEnum.PRODUCER_PLAYBOOK) {
        try {
          const response = await getStreamingResponse(
            { conversationId, message, files: [] },
            currentUser?.id ?? '',
            llm
          );
          handleStreamingResponse(response as Response);
        } catch (error) {
          setIsLoadingResponse(false);
        }
        // } else {
        //   try {
        //     await postMessage(
        //       { conversationId, message: message, files: [] },
        //       llm,
        //     );
        //     setShouldFetchMessages(true);
        //   } catch (error) {
        //     setIsLoadingResponse(false);
        //   }
        // }
      } else if (inputRef.current) {
        const ref = inputRef.current;

        if (ref.value === '' && uploadedFiles.length === 0) {
          setShowErrorMessage('Please enter your message!');
          setTimeout(() => {
            setShowErrorMessage('');
          }, 5000);
          return;
        }

        setIsLoadingResponse(true);
        setShowChatInfo(false);
        const message = getChatPrompt();

        const tempUploadedFiles = [...uploadedFiles];

        setUploadedFiles([]);

        ref.value = '';

        let newConversationTitle;
        if (!conversationId) {
          if (prompt) {
            newConversationTitle = prompt.title;
          } else if (tempUploadedFiles.length > 0) {
            if (!/\s/.test(tempUploadedFiles[0].name) && tempUploadedFiles[0].name.length > 20) {
              const fileExtension = tempUploadedFiles[0].name.slice(
                ((tempUploadedFiles[0].name.lastIndexOf('.') - 1) >>> 0) + 2
              );
              newConversationTitle =
                tempUploadedFiles[0].name.substring(0, 20) + '...' + fileExtension;
            } else {
              newConversationTitle = tempUploadedFiles[0].name;
            }
          } else {
            newConversationTitle = message;
          }

          const newConversation = await postConversation({
            title: newConversationTitle,
            promptId: searchParams.get('prompt') ?? undefined,
            functions: [...functions, ...(prompt?.functions ?? [])],
            appName: prompt ? prompt.appName : appState.selectedApp,
          });

          if (!prompt && message) {
            setMessages([
              ...messages,
              {
                conversationId: '',
                message,
                from: MessageFrom.USER,
                createdAt: '',
                id: '',
                deleted: false,
                tokens: 0,
                files: tempUploadedFiles,
              },
            ]);
          }

          // if (appState.selectedApp !== AppEnum.PRODUCER_PLAYBOOK) {
          try {
            const response = await getStreamingResponse(
              {
                conversationId: newConversation.id,
                message,
                files: tempUploadedFiles,
              },
              currentUser?.id ?? '',
              llm
            );
            handleStreamingResponse(response as Response);
          } catch (error) {
            setIsLoadingResponse(false);
          }
          // } else {
          //   try {

          //     await postMessage(
          //       {
          //         conversationId: newConversation.id,
          //         message: message,
          //         files: tempUploadedFiles,
          //       },
          //       llm,
          //     );

          //     setShouldFetchConversations(true);
          //     setShouldFetchMessages(true);
          //     setUploadedFiles([]);

          //     if (window.location.pathname === '/chat') {
          //       navigate(`/chat/${newConversation.id}`);
          //     }
          //   } catch (error) {
          //     setIsLoadingResponse(false);
          //   }
          // }
        } else {
          setGreyAllMessages(true);

          setMessages([
            ...messages,
            {
              conversationId,
              message,
              from: MessageFrom.USER,
              createdAt: '',
              id: '',
              deleted: false,
              tokens: 0,
              files: tempUploadedFiles,
            },
          ]);

          // if (appState.selectedApp !== AppEnum.PRODUCER_PLAYBOOK) {
          try {
            const response = await getStreamingResponse(
              {
                conversationId,
                message,
                files: tempUploadedFiles,
              },
              currentUser?.id ?? '',
              llm
            );
            handleStreamingResponse(response as Response);
          } catch (error) {
            setIsLoadingResponse(false);
          }
          // } else {
          //   try {
          //     await postMessage(
          //       {
          //         conversationId,
          //         message: message,
          //         files: tempUploadedFiles,
          //       },
          //       llm,
          //     );
          //     setShouldFetchMessages(true);

          //   } catch (error) {
          //     setIsLoadingResponse(false);
          //   }
          // }
        }
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [
      uploadedFiles,
      conversationId,
      searchParams,
      appState.selectedApp,
      features.streaming,
      messages,
      currentUser?.id,
      llm,
      handleStreamingResponse,
      setShouldFetchConversations,
      navigate,
      setIsLoadingResponse,
    ]
  );

  // Triggers when a new prompt is selected
  // updates promptId
  useEffect(() => {
    const newPromptId = searchParams.get('prompt');
    if (newPromptId) {
      setPromptId(newPromptId);
    }
  }, [searchParams]);

  //Triggers when the user triggers a submit, changes the navigation, changes the prompt, or changes the promptId
  //fetches the prompt, sets prompt and functions
  useEffect(() => {
    if (promptId && !prompt) {
      const fetchPrompt = async () => {
        const prompt = await findPrompt(promptId);
        if (prompt.appName === 'PRODUCER_PLAYBOOK') {
          if (!features.producer_playbook) {
            enqueueSnackbar('You do not have access to this prompt', { variant: 'error' });
            navigate('/chat');
            return;
          }
        }

        setPrompt(prompt);
        setChatPrompt(prompt.prompt);
        setUseFunctions(prompt.functions && prompt.functions.length > 0 ? true : false);
        setFunctions(prompt.functions ?? []);
        appState.selectedApp = prompt.appName;

        if (!prompt.userEditRequired) {
          await handleSubmit(prompt);
        }
      };
      fetchPrompt();
    }
  }, [
    handleSubmit,
    navigate,
    prompt,
    promptId,
    enqueueSnackbar,
    features.producer_playbook,
    appState,
  ]);

  useEffect(() => {
    if (currentConversationId && currentConversation && shouldFetchMessages) {
      const fetchMesages = async () => {
        try {
          const newMessages = await getMessages(currentConversationId);
          if (
            currentConversation?.prompt &&
            currentConversation.prompt.userEditRequired === false
          ) {
            if (newMessages.length > 0) {
              newMessages.shift();
            }
          }
          setMessages(newMessages);
          // set tokens based on the most recent message in the conversation where the ai is the sender
          const mostRecentAIMessages = newMessages.filter(
            (message) => message.from === MessageFrom.AI
          );
          setTokens(
            mostRecentAIMessages.length > 0
              ? mostRecentAIMessages[mostRecentAIMessages.length - 1].tokens
              : 0
          );
          setIsLoadingResponse(false);
          setShouldFetchMessages(false);
        } catch (e) {
          enqueueSnackbar('Error fetching messages', { variant: 'error' });
          navigate('/chat');
        }
      };
      fetchMesages();
      setShowBanner(false);
    }
  }, [currentConversation, currentConversationId, enqueueSnackbar, navigate, shouldFetchMessages]);

  useEffect(() => {
    if (currentConversationId !== conversationId) {
      const fetchConversation = async () => {
        if (conversationId) {
          try {
            const newConversation = await getConversation(conversationId);
            setCurrentConversationId(conversationId);
            setCurrentConversation(newConversation);
            appState.selectedApp = newConversation.appName;

            let newFunctions = newConversation.functions ?? [];
            setFunctions(newFunctions);
            newFunctions = Array.from(
              new Set(newFunctions.concat(newConversation?.prompt?.functions ?? []))
            );

            setUseFunctions(newFunctions.length > 0 ? true : false);
            setShouldFetchMessages(true);
            setUploadedFiles([]);
            if (inputRef.current) {
              inputRef.current.value = '';
            }
          } catch (e) {
            enqueueSnackbar('Error fetching conversation', { variant: 'error' });
            setCurrentConversationId(undefined);
            setCurrentConversation(undefined);
          }
        } else {
          setCurrentConversationId(conversationId);
          setCurrentConversation(undefined);
          setFunctions([]);
          setUseFunctions(false);
          setUploadedFiles([]);
          if (inputRef.current) {
            inputRef.current.value = '';
          }
        }
      };
      fetchConversation();
    }
  }, [conversationId, currentConversation, currentConversationId, appState, enqueueSnackbar]);

  useEffect(() => {
    if (shouldFetchConversations) {
      const fetchConversations = async () => {
        const newConversations = await getConversations();
        setConversations(newConversations);
        setShouldFetchConversations(false);
        setGreyAllMessages(false);
      };
      fetchConversations();
    }
  }, [setShouldFetchConversations, shouldFetchConversations]);

  const handleConversationUpdate = (conversationId?: string, fetchConversations = true) => {
    setPrompt(undefined);
    setPromptId(undefined);
    if (fetchConversations) {
      setShouldFetchConversations(true);
    }

    if (conversationId) {
      navigate(`/chat/${conversationId}`);
    } else {
      navigate('/chat');
    }
  };

  useEffect(() => {
    if (currentConversationId && !conversationId) {
      setMessages([]);
      setChatPrompt('');
      setTokens(0);
      setShowBanner(true);
    }
  }, [conversationId, currentConversationId]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages, currentConversationId]);

  useEffect(() => {
    switch (llm) {
      case 'gpt-4o-128k':
        setMaxTokens(128000);
        break;
      case 'gpt-4-1106-preview-llm-build':
        setMaxTokens(128000);
        break;
      case 'gpt-35-turbo':
        setMaxTokens(4096);
        break;
      default:
        setMaxTokens(16000);
        break;
    }
  }, [llm]);

  useEffect(() => {
    setShowChatInfo(window.location.pathname === '/chat');
  }, [navigate]);

  // Function to handle the selecting of function checkboxes
  const updateFunctions = useCallback(
    async (funcNames: FunctionName[]) => {
      setFunctions(funcNames);
      await updateConversation({ functions: funcNames }, conversationId ?? '');
    },
    [conversationId]
  );

  const handleMicClick = () => {
    setMicActive(!micActive);
    if (!micActive) {
      playMicDing();
    }
  };

  useEffect(() => {
    speechToTextContinuous(micActive, (text) => {
      if (inputRef.current && text) {
        inputRef.current.value += text;
      }
    });
  }, [micActive]);

  return (
    <ChatPageContainer>
      <DrawerContainer open={historyDrawerOpen}>
        <Drawer
          variant="persistent"
          anchor="left"
          open={historyDrawerOpen}
          sx={{ height: '100%', width: drawerWidth }}
        >
          <ChatHistory
            conversations={conversations}
            handleConversationUpdate={handleConversationUpdate}
            appEnum={appState.selectedApp}
          />
        </Drawer>
      </DrawerContainer>
      <DrawerToggleButton
        isDrawerOpen={historyDrawerOpen}
        setIsDrawerOpen={setHistoryDrawerOpen}
        tooltipCaption={historyDrawerOpen ? 'Close History Panel' : 'Open History Panel'}
      />

      <MainContainer open={historyDrawerOpen}>
        <div
          style={{ overflow: 'auto', width: '100%', minHeight: '100%' }}
          ref={messageContainerRef}
        >
          <StyledChatContainer maxWidth="md">
            <div style={{ position: 'sticky', zIndex: '999', marginBottom: '10px', top: '0' }}>
              {showBanner && (
                <StyledAlert icon={`🔍`} style={{ backgroundColor: '#8a8e96' }}>
                  <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                      Always double check the output of ASK_IMA for inaccuracies before using it.
                    </Typography>
                  </div>
                </StyledAlert>
              )}
            </div>
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '20px',
              }}
            >
              <FunctionSettings
                functions={functions}
                disabledOnFunctions={currentConversation?.prompt?.functions ?? []}
                handleFunctionsUpdate={updateFunctions}
                useFunctions={useFunctions}
                setUseFunctions={setUseFunctions}
              />
              <ChatInfo
                currentConversation={currentConversation}
                currentPrompt={prompt}
                shouldShowInfo={showChatInfo}
                useFunctions={useFunctions}
              />
            </div>
            <ChatContainer>
              {messages.length === 0 && !prompt?.id && (
                <ChatBubbleComponent
                  key={'placeholder'}
                  message={{
                    from: MessageFrom.AI,
                    message:
                      'Start a new conversation with me by typing in the box or select an old conversation from the left! 😊',
                  }}
                  loading={shouldFetchMessages}
                />
              )}
              {messages.length === 0 && prompt && prompt.userEditRequired && (
                <>
                  <ChatBubbleComponent
                    key={'placeholder'}
                    message={{
                      from: MessageFrom.AI,
                      message: `Looks like you're using a prompt, make sure to edit it if necessary before sending.`,
                    }}
                    loading={shouldFetchMessages}
                  />
                </>
              )}
              {messages.map((message, index) => (
                <ChatBubbleComponent
                  key={index}
                  message={message}
                  loading={(shouldFetchMessages && !greyAllMessages) || isLoadingResponse}
                />
              ))}
              {isLoadingResponse &&
                ((messages.length > 0 && messages[messages.length - 1].from === MessageFrom.USER) ||
                  messages.length === 0) && (
                  <ChatBubbleComponent
                    key={'loading'}
                    message={{ from: MessageFrom.AI }}
                    loading={true}
                  />
                )}
              <ChatRow>
                <div style={{ paddingBottom: '20px', flexGrow: 1 }}>
                  <TextField
                    inputRef={(ref) => (inputRef.current = ref)}
                    variant="outlined"
                    label="Please enter a message"
                    InputProps={{
                      inputProps: {
                        style: { fontSize: '14px' },
                      },
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ alignSelf: 'end', marginBottom: '12px' }}
                        >
                          <Tooltip
                            title={
                              <Typography variant="caption">
                                {micActive ? 'Stop Speech to Text' : 'Start Speech to Text'}
                              </Typography>
                            }
                          >
                            <IconButton
                              component="label"
                              style={{ left: '20px' }}
                              onClick={handleMicClick}
                            >
                              {micActive ? <StyledMic /> : <Mic />}
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              <Typography variant="caption">
                                {' '}
                                Upload a document. Image files are not supported.{' '}
                              </Typography>
                            }
                          >
                            <IconButton component="label" style={{ left: '20px' }}>
                              <input type="file" hidden onChange={handleFileUpload} />
                              <Attachment />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    multiline
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!showErrorMessage}
                    helperText={showErrorMessage}
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey && filesToUpload.length === 0) {
                        e.preventDefault();
                        handleSubmit(prompt);
                      }
                    }}
                    autoComplete="off"
                  />
                  <div>
                    {filesToUpload.map((fileToUpload: File, index: number) => (
                      <div key={index} style={{ padding: '5px' }}>
                        <FileCard
                          fileToUpload={fileToUpload}
                          handleFileDelete={() => {
                            handleFileDelete(index);
                          }}
                        />
                      </div>
                    ))}
                    {uploadedFiles.map((uploadedFile: MessageFile, index: number) => (
                      <div key={index} style={{ padding: '5px', paddingBottom: '10px' }}>
                        <FileCard
                          uploadedFile={uploadedFile}
                          handleFileDelete={() => {
                            handleFileDelete(index);
                          }}
                          handleTextChange={(newText) => {
                            handleTextChange(index, newText);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <LimitWarning tokens={tokens} maxTokens={maxTokens} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    paddingBottom: uploadedFiles.length > 0 ? '60px' : '52px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Tooltip title={<Typography variant="caption"> Send </Typography>}>
                    <span>
                      <SendButton
                        onClick={() => handleSubmit(prompt)}
                        color="primary"
                        disabled={filesToUpload.length > 0 || isLoadingResponse}
                      >
                        <Send />
                      </SendButton>
                    </span>
                  </Tooltip>
                </div>
              </ChatRow>
            </ChatContainer>
          </StyledChatContainer>
        </div>
      </MainContainer>
    </ChatPageContainer>
  );
};

export default Chat;
