import styled from '@emotion/styled';
import {
  AdminPanelSettings,
  DescriptionOutlined,
  LogoutOutlined,
  Person,
  VerifiedUser,
} from '@mui/icons-material';
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_LOGO from '../assets/ima-logo.jpg';
import { useAuth } from './Providers/AuthProvider';
import { useJoyride } from './Providers/JoyrideProvider';

const IconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',

  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

const LogoContainer = styled('div')({
  width: '140px',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  cursor: 'pointer',
  marginRight: '10px',
});

const RoutesContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  minHeight: 'inherit',
  alignItems: 'center',
});

const NavBarContainer = styled('div')({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  width: '100%',
  minHeight: 'inherit',
  justifyContent: 'space-between',
});

const StyledAppBar = styled(AppBar)({
  backgroundColor: 'white',
  color: 'black',
  boxShadow: '0px 2px 2px 2px rgba(16, 24, 40, 0.05)',
  zIndex: '1000',
});

const NavBarMenuItem = styled(MenuItem)({
  '&:hover': {
    outline: '1px solid #b78a1c',
    backgroundColor: 'white',
  },

  '.MuiTouchRipple-child': {
    backgroundColor: '#cce6ff',
  },

  borderRadius: '4px',
  marginRight: '10px',
});

const ProfilePhotoButton = styled(IconButton)({
  position: 'relative',
});

const ProfilePhoto = styled('img')({
  width: '40px',
  height: '35px',
  borderRadius: '50%',
});

const AdminIcon = styled(VerifiedUser)({
  position: 'absolute',
  left: '27px',
  top: '25px',
  color: 'green',
  height: '15px',
  width: '14px',
});

type PageRoute = {
  name: string;
  route: string;
};

const defaultRoutes = [
  {
    name: 'Chat',
    route: '/chat',
  },
  {
    name: 'Prompts',
    route: '/prompts',
  },
  {
    name: 'My Prompts',
    route: '/my-prompts',
  },
  {
    name: 'FAQ',
    route: '/faqs',
  },
];

const NavBar = (): JSX.Element => {
  const { logout, currentUser, isAdmin } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [routes, setRoutes] = useState<PageRoute[]>(defaultRoutes);

  const navigate = useNavigate();
  const location = useLocation();
  const { tourState, setTourState } = useJoyride();
  const profilePhoto = localStorage.getItem('profilePhoto');

  useEffect(() => {
    if (isAdmin) {
      const adminRoutes = [...defaultRoutes];
      adminRoutes.push({
        name: 'User Stats (Admin)',
        route: '/user-stats',
      });
      setRoutes(adminRoutes);
    } else {
      setRoutes(defaultRoutes);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (!currentUser?.metadata.tourCompleted && tourState.tourActive === true) {
      setTourState({ ...tourState, ...{ stepIndex: 0, run: true, tourActive: false } });
    }
  });

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    logout();
  };

  const handleReleaseNotes = () => {
    setAnchorElUser(null);
    navigate('/release-notes');
  };

  return (
    <StyledAppBar position="relative">
      {/* <FeedbackDialog isOpen={feedbackOpen} setIsOpen={setFeedbackOpen} /> */}
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <NavBarContainer>
            {/* TODO figure out a better way to handle this */}
            {currentUser ? (
              <>
                <LogoContainer onClick={() => navigate('/')}>
                  <img src={APP_LOGO} style={{ height: '60px' }} />
                </LogoContainer>
                <RoutesContainer>
                  {routes.map((page) => (
                    <NavBarMenuItem
                      key={page.name}
                      id={page.route.substring(1)}
                      onClick={async () => {
                        navigate(page.route);
                      }}
                      selected={location.pathname.startsWith(page.route)}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          fontWeight: location.pathname.startsWith(page.route) ? '500' : '400',
                        }}
                      >
                        {page.name}
                      </Typography>
                    </NavBarMenuItem>
                  ))}
                </RoutesContainer>
                <Tooltip
                  title={<Typography variant="caption"> Open settings </Typography>}
                  sx={{ height: '40px', width: '40px' }}
                >
                  <ProfilePhotoButton onClick={handleOpenUserMenu}>
                    {isAdmin && profilePhoto ? (
                      <>
                        <ProfilePhoto src={profilePhoto} alt="Profile" />
                        <AdminIcon />
                      </>
                    ) : isAdmin && !profilePhoto ? (
                      <AdminPanelSettings />
                    ) : profilePhoto ? (
                      <ProfilePhoto src={profilePhoto} alt="Profile" />
                    ) : (
                      <Person />
                    )}
                  </ProfilePhotoButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => handleReleaseNotes()}>
                    <Typography textAlign="center">
                      <IconContainer>
                        <DescriptionOutlined />
                        <span>Release Notes</span>
                      </IconContainer>
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => handleLogout()}>
                    <Typography textAlign="center">
                      <IconContainer>
                        <LogoutOutlined />
                        <span>Logout</span>
                      </IconContainer>
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <LogoContainer onClick={() => navigate('/')}>
                <img src={APP_LOGO} style={{ height: '30px' }} />
              </LogoContainer>
            )}
          </NavBarContainer>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default NavBar;
