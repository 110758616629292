import { StatusError } from '../components/Providers/ErrorProvider';
import { User } from '../Types/user';

export const API_URL = import.meta.env.VITE_APP_API_HOST;

let setErrorFunction: ((error: StatusError) => Promise<boolean>) | null;
setErrorFunction = null;

export const registerErrorFunction = (fn: (error: StatusError) => Promise<boolean>) => {
  setErrorFunction = fn;
};

export const enrichedFetch = async (
  url: string,
  options = {} as RequestInit,
  retryTimes = 0,
  responseJson = true
): Promise<unknown> => {
  const handleError = async (error: string, status: number) => {
    if (setErrorFunction) {
      return setErrorFunction(new StatusError(error, status));
    } else {
      console.error(error);
    }
  };

  const headers = new Headers(options.headers);
  if (localStorage.getItem('token')) {
    headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  }
  if (localStorage.getItem('graphToken')) {
    headers.set('GraphToken', `Bearer ${localStorage.getItem('graphToken')}`);
  }
  headers.set('Timezone', `${Intl.DateTimeFormat().resolvedOptions().timeZone}`);

  if (localStorage.getItem('jwt')) {
    headers.set('Jwt', `${localStorage.getItem('jwt')}`);
  }

  options.headers = headers;

  let response = null;
  try {
    response = await fetch(url, { ...options, credentials: 'include', mode: 'cors' });
  } catch (error) {
    const errorMessage = 'Failed to connect to server';
    handleError(errorMessage, 0);
    throw new StatusError(errorMessage, 0);
  }

  if (response.status === 204) return response;

  if (response.status === 401) {
    const error = 'Unauthorized';
    const handler = await handleError(error, response.status);
    if (handler && retryTimes < 1) {
      console.log('User session was stale, retrying');
      return enrichedFetch(url, options, 1);
    } else {
      console.log('User refresh failed, logging out');
      throw new Error(error);
    }
  }

  if (response.status < 200 || response.status >= 300) {
    const responseMessage = (await response.json()).message;

    let error = responseMessage ?? 'Failed API Call';
    if (responseMessage.startsWith("This model's maximum context")) {
      error =
        'Sorry, this conversation is too long to process. Until we use a bigger model, please try to use smaller conversations if possible.';
    }
    handleError(error, response.status);
    return response;
  } else {
    if (responseJson) return response.json();
  }
  return response;
};

export const login = (email?: string, password?: string): Promise<User> => {
  const options: RequestInit = {
    method: 'GET',
  };

  if (email && password) {
    options.body = JSON.stringify({
      email,
      password,
    });
    options.method = 'POST';
    options.headers = {
      'Content-Type': 'application/json',
    };
  }

  return enrichedFetch(`${API_URL}/user/login`, options) as Promise<User>;
};

export const loginAzureAD = (): Promise<User> => {
  return enrichedFetch(`${API_URL}/user/login/azuread`) as Promise<User>;
};

export const logoutUser = (): Promise<User> => {
  return enrichedFetch(`${API_URL}/user/logout`, {
    method: 'POST',
  }) as Promise<User>;
};

export const logoutToken = (): Promise<User> => {
  return enrichedFetch(`${API_URL}/user/logout/token`, {
    method: 'POST',
  }) as Promise<User>;
};

export const loginToken = (): Promise<User> => {
  return enrichedFetch(`${API_URL}/user/loginToken`) as Promise<User>;
};

export const getAzureGraphToken = (): Promise<string> => {
  return enrichedFetch(`${API_URL}/user/graphToken`) as Promise<string>;
};
