import styled from '@emotion/styled';
import { Alert, Button, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../components/Providers/AuthProvider';
import { logoutToken } from '../services/api';

const MainContainer = styled('div')({
  height: 'inherit',
  display: 'flex',
});

const LoginPage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'inherit',
  minWidth: '50%',
});

const LoginSection = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  marginLeft: '10px',
  marginRight: '10px',
  width: '360px',
  height: 'fit-content',
  '> *:not(:last-child)': {
    marginBottom: '20px',
  },
  zIndex: 2,

  marginBottom: '60px',

  '@media (min-width: 1200px)': {
    marginBottom: '100px',
  },
});

const DisclaimerAlert = styled(Alert)({
  backgroundColor: '#D3EAFF',
  position: 'absolute',
  bottom: '32px',
  left: '20px',
});

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  // use the auth context to get the login function
  const { currentUser, authenticateAzureAD } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const ssoLogin = async () => {
    await logoutToken();
    const searchParams = new URLSearchParams(window.location.search);
    const redirectTo = searchParams.get('redirect') || '/';
    authenticateAzureAD(redirectTo);
  };

  return (
    <MainContainer>
      <LoginPage>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoginSection>
            <Typography sx={{ fontWeight: '700', fontSize: '34px' }}>Join the Movement.</Typography>
            <Button onClick={ssoLogin} variant="contained">
              Login with SSO
            </Button>
          </LoginSection>
        </div>
      </LoginPage>
      <DisclaimerAlert severity="info">This app is under active development.</DisclaimerAlert>
    </MainContainer>
  );
};

export default Login;
