import { Container, styled, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

import CustomDataGridToolbar from '../components/UserStats/CustomDataGridToolbar';
import { getUserStats } from '../services/stats';
import { UserStat } from '../Types/stats';

const UserStatsDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '20px',
  paddingRight: '100px',
  paddingLeft: '100px',
});

const formatNumber = (num: number) => {
  if (Math.abs(num) >= 1.0e9) {
    return (Math.abs(num) / 1.0e9).toFixed(1) + 'B';
  } else if (Math.abs(num) >= 1.0e6) {
    return (Math.abs(num) / 1.0e6).toFixed(1) + 'M';
  } else if (Math.abs(num) >= 1.0e3) {
    return (Math.abs(num) / 1.0e3).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
};

const columns: GridColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    renderHeader: () => <strong>Email</strong>,
  },
  {
    field: 'messages_sent_and_received',
    type: 'number',
    headerName: 'Messages Sent & Received',
    width: 200,
    renderHeader: () => <strong>Messages Sent & Received</strong>,
    renderCell: (params) => formatNumber(params.value),
  },
  {
    field: 'token_usage',
    headerName: 'Token Usage',
    type: 'number',
    width: 100,
    renderHeader: () => <strong>Token Usage</strong>,
    renderCell: (params) => formatNumber(params.value),
  },
  {
    field: 'tokens_per_message',
    headerName: 'Tokens/Message',
    type: 'number',
    width: 200,
    renderHeader: () => <strong>Tokens/Message</strong>,
    renderCell: (params) => formatNumber(params.value),
  },
];

const UserStatsPage = (): JSX.Element => {
  const [userStats, setUserStats] = useState<UserStat[]>([]);
  const [shouldFetchStats, setShouldFetchStats] = useState<boolean>(true);

  useEffect(() => {
    const fetchStats = async () => {
      const newStats = await getUserStats();
      setUserStats(newStats);
      setShouldFetchStats(false);
    };
    if (shouldFetchStats) {
      fetchStats();
    }
  }, [shouldFetchStats]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', overflowY: 'auto', height: '100%' }}>
      <Container sx={{ bgcolor: 'white', height: 'fit-content', margin: '0px', minHeight: '100%' }}>
        <UserStatsDiv>
          <Typography sx={{ fontSize: '36px', fontWeight: '700', marginBottom: '20px' }}>
            User Stats
          </Typography>
          {
            <div style={{ height: '80%', width: '100%' }}>
              <DataGrid
                slotProps={{
                  panel: {
                    sx: {
                      top: '-50px !important',
                    },
                  },
                }}
                rows={userStats}
                columns={columns}
                slots={{ toolbar: CustomDataGridToolbar }}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 50, 100]}
              />
            </div>
          }
        </UserStatsDiv>
      </Container>
    </div>
  );
};

export default UserStatsPage;
