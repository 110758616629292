export enum AppEnum {
  IMAGPT = 'IMAGPT',
  PRODUCER_PLAYBOOK = 'PRODUCER_PLAYBOOK',
}

export enum MessageFrom {
  AI = 'AI',
  USER = 'USER',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum Tag {
  PRODUCERPLAYBOOK = 'Producer Playbook',
  IMAGPT = 'IMA GPT',
}

export enum FunctionName {
  GREETINGS = 'greetings',
}

export const FunctionNameMap = {
  [FunctionName.GREETINGS]: 'Greetings',
};

export enum PromptSortOptions {
  LIKES = 'LIKES',
  USAGE = 'USAGE',
  DEFAULT = 'DEFAULT',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
