import styled from '@emotion/styled';
import { LocalParking, MoreHoriz } from '@mui/icons-material';
import { CardContent, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import * as filesaver from 'file-saver';
import React, { useState } from 'react';

import { deleteConversation, downloadFile, updateConversation } from '../../services/conversation';
import { Conversation } from '../../Types/conversation';
import timeSince from '../../util/timeUitls';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import RenameDialog from './RenameDialog';

const ConversationCard = styled('div')<{ selected: boolean }>(({ selected }) => ({
  width: '100%',
  ':hover': {
    cursor: 'pointer',
    boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.05)',
  },
  borderRadius: '4px',
  boxShadow: '0px 4px 12px 6px rgba(16, 24, 40, 0.03)',

  WebkitTapHighlightColor: 'transparent',
  display: 'block',
  WebkitTextDecoration: 'none',
  textDecoration: 'none',
  background: selected ? '#eaf1f5' : '#fff',
  border: selected ? '2px solid #0076cc' : '2px solid #fff',
  borderColor: selected ? '#b78a1c' : '#fff',
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  padding: '0.2rem',
}));

const StyledConversationCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '10px',
  padding: '10px',
  ':last-child': {
    paddingBottom: '10px',
  },
});

const IconButtonContainer = styled('div')({
  right: '0px',
  top: '0px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

const ChatCard = ({
  onConversationCardClick,
  conversation,
}: {
  conversation: Conversation;
  onConversationCardClick: (conversationId?: string) => void;
}) => {
  const [anchorContextMenu, setAnchorContextMenu] = useState<null | HTMLElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);

  const handleConversationDelete = async (conversationId: string) => {
    await deleteConversation(conversationId);
    onConversationCardClick();
  };

  const handleOpenChatContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorContextMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorContextMenu(null);
  };

  const handleConversationRename = async (conversationId: string, newTitle: string) => {
    await updateConversation({ title: newTitle }, conversationId);
    onConversationCardClick(conversationId);
    handleCloseUserMenu();
  };

  const handleConversationDownload = async (conversation: Conversation) => {
    const response = await downloadFile(conversation.id);
    filesaver.saveAs(
      new Blob([response.conversation]),
      `${
        conversation.title.length > 50
          ? conversation.title.slice(0, 50).concat('...')
          : conversation.title
      }.md`
    );
  };

  return (
    <ConversationCard
      onClick={() => onConversationCardClick(conversation.id)}
      selected={window.location.pathname.includes(conversation.id)}
    >
      <StyledConversationCardContent>
        <IconButtonContainer>
          <Typography>
            {conversation.title.length > 50
              ? conversation.title.slice(0, 50).concat('...')
              : conversation.title}
          </Typography>
          <div onClick={(e) => e.stopPropagation()}>
            <DeleteConfirmationDialog
              isOpen={isDeleteDialogOpen}
              setIsOpen={setIsDeleteDialogOpen}
              onDelete={() => handleConversationDelete(conversation.id)}
            />
            <RenameDialog
              isOpen={isRenameDialogOpen}
              setIsOpen={setIsRenameDialogOpen}
              currentName={conversation.title}
              onRename={(newTitle) => handleConversationRename(conversation.id, newTitle)}
            />
            <Tooltip title={<Typography variant="caption"> Open settings </Typography>}>
              <IconButton onClick={handleOpenChatContextMenu} sx={{ p: 0 }}>
                <MoreHoriz />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '32px' }}
              anchorEl={anchorContextMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorContextMenu)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => setIsRenameDialogOpen(true)}>
                <Typography textAlign="center">Rename</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleConversationDownload(conversation)}>
                <Typography textAlign="center">Download Conversation (.md)</Typography>
              </MenuItem>
              <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
                <Typography textAlign="center">Delete</Typography>
              </MenuItem>
            </Menu>
          </div>
        </IconButtonContainer>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="caption" sx={{ color: 'rgba(0,0,0,0.5)' }}>
            {timeSince(new Date(conversation.createdAt))}
          </Typography>
          {conversation.appName == 'PRODUCER_PLAYBOOK' && (
            <Tooltip title="Conversation uses producer playbook data">
              <LocalParking sx={{ fontSize: '1.2rem', color: 'rgba(0,0,0,0.5)' }} />
            </Tooltip>
          )}
        </div>
      </StyledConversationCardContent>
    </ConversationCard>
  );
};

export default ChatCard;
