import styled from '@emotion/styled';
import { MenuOpen } from '@mui/icons-material';
import { Fab, Tooltip, Typography } from '@mui/material';
import React from 'react';

const CloseIconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  marginTop: '5px',

  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

const IconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  position: 'absolute',
  top: '5px',

  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

const DrawerToggleFab = styled(Fab)({
  backgroundColor: 'white',
  position: 'absolute',
  marginLeft: '10px',
  height: '30px',
  width: '30px',
  minHeight: '0',
  boxShadow: 'none',
});

const DrawerToggleButton = ({
  isDrawerOpen,
  setIsDrawerOpen,
  tooltipCaption,
}: {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (newValue: boolean) => void;
  tooltipCaption: string;
}) => {
  return isDrawerOpen ? (
    <CloseIconContainer>
      <Tooltip key={'open'} title={<Typography variant="caption">{tooltipCaption}</Typography>}>
        <DrawerToggleFab onClick={() => setIsDrawerOpen(false)}>
          <MenuOpen sx={{ height: '20px', width: '20px' }} />
        </DrawerToggleFab>
      </Tooltip>
    </CloseIconContainer>
  ) : (
    <IconContainer>
      <Tooltip key="close" title={<Typography variant="caption">{tooltipCaption}</Typography>}>
        <DrawerToggleFab onClick={() => setIsDrawerOpen(true)} size="small">
          <MenuOpen sx={{ transform: 'rotate(180deg)', height: '20px', width: '20px' }} />
        </DrawerToggleFab>
      </Tooltip>
    </IconContainer>
  );
};

export default DrawerToggleButton;
