import { ContentCopy, Download, MoreHoriz } from '@mui/icons-material';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import * as fileSaver from 'file-saver';
import React, { ComponentPropsWithoutRef, useRef, useState } from 'react';
import type { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

import useCopyToClipboard from '../hooks/useCopyToClipboard';
import { parseTableToCSV } from '../util/stringUtils';

type MarkdownTableProps = ComponentPropsWithoutRef<'table'> & ReactMarkdownProps;

const StyledTable = styled('table')({
  borderSpacing: '0',
  borderCollapse: 'collapse',
  display: 'block',
  marginTop: '0',
  marginBottom: '0',
  width: 'max-content',
  maxWidth: '100%',
  overflow: 'auto',

  tr: {
    backgroundColor: '#ffffff',
    borderTop: '1px solid ' + 'hsla(210, 18%, 87%, 1)',

    '&:nth-child(2n)': {
      backgroundColor: '#f6f8fa',
    },
  },

  'td, th': {
    padding: '6px 13px',
    border: '1px solid ' + '#d0d7de',
  },

  th: {
    fontWeight: '600',
  },

  img: {
    backgroundColor: 'transparent',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
});

const MarkdownTable = ({ className, children }: MarkdownTableProps) => {
  const [anchorContextMenu, setAnchorContextMenu] = useState<null | HTMLElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const { copySuccess, copyToClipboard } = useCopyToClipboard();

  const handleCopyToClipboard = () => {
    if (!tableRef.current) return;
    const tableText = tableRef.current.innerText;
    copyToClipboard(tableText);
  };

  const handleCopyToCSV = () => {
    if (!tableRef.current) return;
    const tableText = tableRef.current.innerText;

    const parsedCSVContent = parseTableToCSV(tableText);
    const fileName = `${parsedCSVContent.split(',')[0]}_csv_export.csv`;
    fileSaver.saveAs(new Blob([parsedCSVContent], { type: 'text/csv' }), fileName);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorContextMenu(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorContextMenu(null);
  };

  return (
    <div>
      <StyledTable className={className}>
        <>
          <Tooltip title={<Typography variant="caption">Open table options</Typography>}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHoriz />
            </IconButton>
          </Tooltip>
          <Menu
            open={Boolean(anchorContextMenu)}
            anchorEl={anchorContextMenu}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleCopyToClipboard}>
              <ListItemIcon>
                <ContentCopy />
              </ListItemIcon>
              <ListItemText>{copySuccess ? 'Copied!' : 'Copy table to clipboard'}</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleCopyToCSV}>
              <ListItemIcon>
                <Download />
              </ListItemIcon>
              <ListItemText>Download CSV</ListItemText>
            </MenuItem>
          </Menu>
        </>
        <div ref={tableRef}>{children}</div>
        <p style={{ opacity: '0.5' }}>
          <i>
            Note: Don&apos;t use ASK_IMA for any math operations on this table, it will be
            incorrect.
          </i>
        </p>
      </StyledTable>
    </div>
  );
};

export default MarkdownTable;
