import { ContentCopy, Person, VolumeUp } from '@mui/icons-material';
import { IconButton, keyframes } from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';
import removeMarkdown from 'remove-markdown';
import { AzureIndexCitation, ToolMessageContent } from 'Types/azureIndexCitations';

import COMPANY_LOGO from '../../assets/ima-logo.jpg';
import { MessageFrom } from '../../enums';
import { textToSpeech } from '../../services/speech';
import { Message, MessageFile } from '../../Types/conversation';
import MarkdownTable from '../MarkdownTable';
import CodeCopyButton from './CodeCopyButton';
import FileCard from './FileCard';

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)({
  borderRadius: '4px',
});

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ChatBubbleContainer = styled('div')({
  fontSize: '14px',
  letterSpacing: '0.25px',
  '& > *': {
    fontFamily: 'Open Sans',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
});

const ChatIcon = styled('img')({
  width: '30px',
});

const SpinningChatIcon = styled(ChatIcon)({
  animation: `${rotateAnimation} 2s linear infinite`,
});

const PersonIcon = styled(Person)({
  width: '20px',
});

const CodePre = styled('pre')({
  position: 'relative',
  overflowX: 'auto',
});

const ChatCard = styled('div')(({ sender }: { sender: MessageFrom }) => ({
  borderRadius: '4px',

  margin: '2px 0',
  outline: 0,
  WebkitTapHighlightColor: 'transparent',
  WebkitTextDecoration: 'none',
  textDecoration: 'none',
  color: '#000',
  boxSizing: 'border-box',
  position: 'relative',
  padding: '1rem',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  backgroundColor: sender !== 'AI' ? '#eaf1f5' : '#fff',
}));

function parseMessage(message: string, azureIndexCitations: AzureIndexCitation[]) {
  let messageText = message;
  const citationLinks = messageText.match(/\[(doc\d\d?\d?)]/g);

  const lengthDocN = '[doc'.length;
  const filteredCitations = [] as AzureIndexCitation[];
  let citationReindex = 0;
  citationLinks?.forEach((link) => {
    const citationIndex = link.slice(lengthDocN, link.length - 1);
    const citation = azureIndexCitations[Number(citationIndex) - 1] as AzureIndexCitation;
    if (!filteredCitations.find((c) => c.id === citationIndex) && citation) {
      messageText = messageText.replaceAll(link, ` ^${++citationReindex}^ `);
      citation.id = citationIndex; // original doc index to de-dupe
      citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
      filteredCitations.push(citation);
    }
  });

  if (filteredCitations.length === 1) {
    messageText += '\n\n  **Citation:**';
  } else if (filteredCitations.length > 1) {
    messageText += '\n\n  **Citations:**';
  }

  return { messageText, filteredCitations };
}

const renderMessageWithCitations = (
  message: string,
  citations: string[],
  azureIndexCitations: string
) => {
  if (citations && citations.length > 0) {
    const formatToMarkdown = (text: string, links: string[]): string => {
      const markdownLinks = links.map((citation) => `- ${citation}`).join('\n');
      return `${text}\n\nCitations:\n${markdownLinks}\n`;
    };
    return formatToMarkdown(message, citations);
  }

  if (azureIndexCitations && azureIndexCitations.length > 0) {
    const toolMessage: ToolMessageContent = JSON.parse(azureIndexCitations) as ToolMessageContent;
    const { messageText, filteredCitations } = parseMessage(message, toolMessage.citations);
    return (
      <>
        <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>{messageText}</ReactMarkdown>
        {filteredCitations.map((citation, index) => (
          <div key={index} style={{ padding: '5px' }}>
            <FileCard azureCitations={citation} />
          </div>
        ))}
      </>
    );
  }
  return message;
};

const renderMessage = (message: string, citations: string[]) => {
  if (citations && citations.length > 0) {
    const formatToMarkdown = (text: string, links: string[]): string => {
      const markdownLinks = links.map((citation) => `- ${citation}`).join('\n');
      return `${text}\n\nCitations:\n${markdownLinks}`;
    };
    return formatToMarkdown(message, citations);
  }

  return message;
};

const cleanRenderedMessage = (text: string) => {
  text = removeMarkdown(text);
  return text.replace(/\[doc\d+\]/g, '');
};

const CopyTextButton: React.FC<{ text: string }> = ({ text }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const handleCopy = async () => {
    try {
      const cleanText = cleanRenderedMessage(text);
      await navigator.clipboard.writeText(cleanText);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (error) {
      console.error('Failed to copy text:', error);
    }
  };

  return (
    <IconButton
      onClick={handleCopy}
      aria-label="Copy text"
      size="small"
      style={{ marginLeft: '8px', color: copySuccess ? 'green' : 'grey' }}
    >
      <ContentCopy fontSize="small" />
    </IconButton>
  );
};

const TextSpeechButton: React.FC<{ text: string }> = ({ text }) => {
  const [textToSpeechSuccess, setTextToSpeechSuccess] = useState(false);
  const handleTextToSpeech = async () => {
    if (textToSpeechSuccess) {
      return;
    }
    setTextToSpeechSuccess(true);
    try {
      const cleanText = cleanRenderedMessage(text);
      await textToSpeech(cleanText);
    } catch (error) {
      console.error('Failed to speak text:', error);
    } finally {
      setTextToSpeechSuccess(false);
    }
  };

  return (
    <IconButton
      onClick={handleTextToSpeech}
      aria-label="Text to Speech"
      size="small"
      style={{ marginLeft: '8px', color: textToSpeechSuccess ? 'blue' : 'grey' }}
    >
      <VolumeUp fontSize="small" />
    </IconButton>
  );
};

const ChatBubbleComponent = ({
  message,
  loading = false,
}: {
  message: Partial<Message>;
  loading?: boolean;
}) => {
  const isCodeSnippet = message.message?.includes('```');
  const isTable = message.message?.includes('|');
  const isCopyable = message.from === MessageFrom.AI && !isCodeSnippet && !isTable;

  return (
    <ChatCard
      sender={message.from ?? MessageFrom.AI}
      sx={{
        opacity: loading ? '60%' : '100%',
        transition: '0.5s',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            {message.from === MessageFrom.USER ? (
              <PersonIcon />
            ) : loading ? (
              <SpinningChatIcon src={COMPANY_LOGO} alt="COMPANY_LOGO" />
            ) : (
              <ChatIcon src={COMPANY_LOGO} alt="COMPANY_LOGO" />
            )}
          </div>
          <div>
            {isCopyable && <CopyTextButton text={message.message ?? ''} />}
            {isCopyable && <TextSpeechButton text={message.message ?? ''} />}
          </div>
        </div>
        <ChatBubbleContainer sx={{ lineHeight: '1.5rem' }}>
          {message.from === MessageFrom.AI ? (
            message.azureCitations ? (
              //Both Conditions True
              <>
                {renderMessageWithCitations(
                  message.message ?? '',
                  message.citations ?? [],
                  message.azureCitations
                )}
              </>
            ) : (
              //Only MessageFromAI is true
              <ReactMarkdown
                linkTarget={'_blank'}
                remarkPlugins={[remarkGfm, supersub]}
                components={{
                  pre: ({ className, children }) => (
                    <CodePre className={className}>
                      <CodeCopyButton>{children}</CodeCopyButton>
                      {children}
                    </CodePre>
                  ),
                  table: (props) => <MarkdownTable {...props} />,
                  code({ inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match ? (
                      <StyledSyntaxHighlighter
                        {...props}
                        language={match[1]}
                        style={a11yDark}
                        PreTag="div"
                      >
                        {String(children).replace(/\n$/, '')}
                      </StyledSyntaxHighlighter>
                    ) : (
                      <code {...props} style={{ wordWrap: 'normal' }} className={className}>
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {renderMessage(message.message ?? '', message.citations ?? [])}
              </ReactMarkdown>
            )
          ) : (
            <div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {message.files && Array.isArray(message.files)
                  ? message.files.map((uploadedFile: MessageFile, index: number) => (
                      <div key={index} style={{ padding: '5px' }}>
                        <FileCard uploadedFile={uploadedFile} />
                      </div>
                    ))
                  : null}
              </div>
              <p style={{ whiteSpace: 'pre-wrap' }}>{message.message}</p>
            </div>
          )}
        </ChatBubbleContainer>
      </>
    </ChatCard>
  );
};

export default ChatBubbleComponent;
