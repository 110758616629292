import {
  Conversation,
  ConversationResponse,
  CreateConversation,
  CreateMessage,
  DownloadConversationResponse,
  Message,
  MessageFile,
  UpdateConversation,
} from '../Types/conversation';
import { User } from '../Types/user';
import { API_URL, enrichedFetch } from './api';

// export const getConversations = ({
//   pageOffset = 0,
//   searchQuery = '',
// }): Promise<ConversationResponse> => {
//   let url = `${API_URL}/conversations?offset=${pageOffset}`;
//   if (searchQuery.trim().length > 0) {
//     url += `&q=${searchQuery}`;
//   }
//   return enrichedFetch(url) as Promise<ConversationResponse>;
// };

export const getConversations = (): Promise<Conversation[]> => {
  return enrichedFetch(`${API_URL}/conversations`) as Promise<Conversation[]>;
};

export const getConversation = (id: Conversation['id']): Promise<Conversation> => {
  return enrichedFetch(`${API_URL}/conversations/${id}`) as Promise<Conversation>;
};

export const postConversation = (conversation: CreateConversation): Promise<Conversation> => {
  return enrichedFetch(`${API_URL}/conversations`, {
    method: 'POST',
    body: JSON.stringify(conversation),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Conversation>;
};

export const updateConversation = async (
  updatedFields: UpdateConversation,
  conversationId: Conversation['id']
): Promise<void> => {
  await enrichedFetch(`${API_URL}/conversations/${conversationId}`, {
    method: 'PUT',
    body: JSON.stringify(updatedFields),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteConversation = async (conversationId: Conversation['id']): Promise<void> => {
  await enrichedFetch(`${API_URL}/conversations/${conversationId}`, {
    method: 'DELETE',
  });
};

export const getMessages = (conversationId: Conversation['id']): Promise<Message[]> => {
  return enrichedFetch(`${API_URL}/conversations/messages/${conversationId}`) as Promise<Message[]>;
};

export const postMessage = (message: CreateMessage, llm: string): Promise<Message> => {
  return enrichedFetch(`${API_URL}/conversations/messages`, {
    method: 'POST',
    body: JSON.stringify({ message, llm }),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Message>;
};

export const postFile = (formData: FormData): Promise<MessageFile> => {
  return enrichedFetch(`${API_URL}/conversations/messages/file/upload`, {
    method: 'POST',
    body: formData,
  }) as Promise<MessageFile>;
};

export const downloadFile = (
  conversationId: Conversation['id']
): Promise<DownloadConversationResponse> => {
  return enrichedFetch(`${API_URL}/conversations/download/${conversationId}`, {
    method: 'GET',
  }) as Promise<DownloadConversationResponse>;
};

export const getStreamingResponse = async (
  message: CreateMessage,
  userId: User['id'],
  llm: string
) => {
  const result = await enrichedFetch(
    `${API_URL}/streaming`,
    {
      method: 'POST',
      body: JSON.stringify({ message, userId, llm }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    0,
    false
  );

  return result;
};
