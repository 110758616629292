import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { AppEnum } from '../../Types/enums';

type AppState = { selectedApp: AppEnum };

const defaultAppState = {
  selectedApp: AppEnum.IMAGPT,
} as AppState;

export const AppContext = createContext({
  appState: defaultAppState,
  setCurrentApp: (() => undefined) as (newSelectedApp: AppEnum) => void,
});

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = (props) => {
  const [appState, setAppState] = useState<AppState>(defaultAppState);

  const setCurrentApp = useCallback(
    (newApp: AppEnum) => {
      setAppState({ ...appState, ...{ selectedApp: newApp } });
    },
    [appState]
  );

  const value = useMemo(
    () => ({
      appState,
      setCurrentApp,
    }),
    [appState, setCurrentApp]
  );

  return <AppContext.Provider value={value} {...props} />;
};

export const useAppProvider = (): {
  appState: AppState;
  setCurrentApp: (newApp: AppEnum) => void;
} => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error('useAppProvider must be used within a AppProvider');
  }
  return appContext;
};
