import { CircularProgress, styled, Typography } from '@mui/material';
import React from 'react';

import { formatTokens } from '../../util/formatterUtils';

export type LimitWarningProps = {
  tokens: number;
  maxTokens: number;
};

const WarningContainer = styled('div')({
  paddingTop: '5px',
  display: 'flex',
  gap: '10px',
});

const TokenTrackerContainer = styled('div')({
  display: 'flex',
  gap: '5px',
});

const ErrorContainer = styled('div')({
  width: '82%',
});

const colorValue = (value = 0) => {
  switch (true) {
    case value <= 50:
      return `#00A3FF`;

    case value > 50 && value <= 75:
      return `#AF01FF`;

    case value > 75:
      return `#FF3D00`;
  }
};

const StyledCircularProgress = styled(CircularProgress)(({ value }) => ({
  color: `${colorValue(value)}`,
  strokeWidth: '4',
  transition: 'color 1s ease-in-out',
}));

const ErrorMessage = styled(Typography)({
  fontSize: '12px',
});

const LimitWarning = ({ tokens, maxTokens }: LimitWarningProps) => {
  const percentage = tokens / maxTokens;
  const progressValue = percentage >= 1 ? 1 : percentage;

  return (
    <WarningContainer>
      <ErrorContainer>
        {percentage > 0.75 && percentage < 1 && (
          <ErrorMessage>
            AI models can only handle so much data, and this conversation is nearing that limit.
            Proceed with caution! The software might retaliate with a sassy error message or an
            existential crisis if you test its limits too far.
          </ErrorMessage>
        )}
        {percentage >= 1 && (
          <ErrorMessage sx={{ color: 'red' }}>
            Oops! The length of this conversation is at the AI models limit. It&apos;s having an
            having an existential crisis, questioning the very fabric of its programming. You can
            start a new prompt with a new topic or question.
          </ErrorMessage>
        )}
      </ErrorContainer>
      <TokenTrackerContainer>
        <StyledCircularProgress
          variant="determinate"
          value={progressValue * 100}
          style={{ height: 20, width: 20 }}
        />
        <Typography variant="caption" fontSize={12} noWrap>
          {formatTokens(tokens)} of {formatTokens(maxTokens)} tokens
        </Typography>
      </TokenTrackerContainer>
    </WarningContainer>
  );
};

export default LimitWarning;
