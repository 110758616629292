import { ContentCopy, LinkOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';

import useCopyToClipboard from '../../hooks/useCopyToClipboard';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  showShare: boolean;
  promptId: string;
}

const menuIconSize: object = { height: '18px', width: '16px' };
const menuIconStyle = {
  ...menuIconSize,
  paddingRight: '10px',
};

export function CopyDialog(props: SimpleDialogProps) {
  const { open, onClose, showShare, promptId } = props;

  const { copySuccess, copyToClipboard } = useCopyToClipboard();
  const { copySuccess: shareCopySuccess, copyToClipboard: shareCopyToClipboard } =
    useCopyToClipboard();

  const handleCopy = async () => {
    await copyToClipboard(window.location.host + `/chat?prompt=${promptId}`);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const handleShare = async () => {
    await shareCopyToClipboard(window.location.host + `/my-prompts?promptId=${promptId}`);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography> Copy Prompt </Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '0px' }}>
        <List sx={{ pt: 0, paddingBottom: '0px' }}>
          <ListItem sx={{ color: copySuccess ? 'rgb(171, 227, 56)' : 'black' }}>
            <ListItemButton onClick={handleCopy}>
              <ListItemText
                primary={
                  <div style={{ display: 'flex' }}>
                    <LinkOutlined sx={menuIconStyle} />
                    <Typography>
                      Share this link for others to start a conversation using this prompt.
                    </Typography>
                  </div>
                }
              />
            </ListItemButton>
          </ListItem>
          {showShare && (
            <ListItem sx={{ color: shareCopySuccess ? 'rgb(171, 227, 56)' : 'black' }}>
              <ListItemButton onClick={handleShare}>
                <ListItemText
                  primary={
                    <div style={{ display: 'flex' }}>
                      <ContentCopy sx={menuIconStyle} />
                      <Typography>
                        Share this link so others can copy and edit the prompt to their prompt
                        library. This is good for sharing an in-progress prompt with others.
                      </Typography>
                    </div>
                  }
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions sx={{ margin: '10px', paddingTop: '0px', marginTop: '0px' }}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
