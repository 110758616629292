import {
  AudioConfig,
  ResultReason,
  SpeechConfig,
  SpeechRecognizer,
  SpeechSynthesizer,
} from 'microsoft-cognitiveservices-speech-sdk';

// Create an instance of SpeechConfig with your Azure subscription key and service region.
const speechConfig = SpeechConfig.fromSubscription(
  import.meta.env.VITE_SPEECH_KEY,
  import.meta.env.VITE_SPEECH_REGION
);

// Create an instance of AudioConfig for using microphone as audio input.
const audioConfig = AudioConfig.fromDefaultMicrophoneInput();

// Create an instance of SpeechRecognizer.
const recognizer = new SpeechRecognizer(speechConfig, audioConfig);

// function to start continuous speech recognition
export const speechToTextContinuous = (micActive: boolean, callback: (text: string) => void) => {
  if (micActive) {
    recognizer.recognized = (s, e) => {
      if (e.result.reason === ResultReason.RecognizedSpeech) {
        callback(e.result.text);
      }
    };
    recognizer.startContinuousRecognitionAsync();
  } else {
    recognizer.stopContinuousRecognitionAsync();
  }
};

//function to read text and convert to speech
export const textToSpeech = (text: string) => {
  const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
  const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);
  return new Promise((resolve, reject) => {
    synthesizer.speakTextAsync(
      text,
      (result) => {
        if (result) {
          resolve(synthesizer);
        } else {
          reject('Text to speech failed');
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
};
