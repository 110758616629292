import styled from '@emotion/styled';
import { OpenInNew } from '@mui/icons-material';
import {
  Alert,
  Button,
  Container,
  Drawer,
  Grid,
  MenuItem,
  Pagination,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import DrawerToggleButton from '../components/DrawerToggleButton';
import PromptCard from '../components/Prompts/PromptCard';
import PromptEdit from '../components/Prompts/PromptEdit';
import PromptFilters from '../components/Prompts/PromptFilters';
import { useAuth } from '../components/Providers/AuthProvider';
import { useFeatures } from '../components/Providers/FeatureProvider';
import { useDebounce } from '../hooks/useDebounce';
import { findPrompt, findPrompts, findPromptTags } from '../services/prompts';
import { PromptSortOptions, SortDirection, Tag } from '../Types/enums';
import { Prompt } from '../Types/prompt';
import { TagCounts } from '../Types/tags';

const MainDiv = styled('div')({
  display: 'flex',
  height: 'inherit',
  div: {
    position: 'relative',
  },
});
type ContainerProps = {
  open: boolean;
  drawerWidth: string;
};
const DrawerContainer = styled('div')<ContainerProps>(({ open, drawerWidth }) => ({
  width: open ? `calc(${drawerWidth})` : 'auto',
  zIndex: '1',
}));

const PromptsContainer = styled('div')<ContainerProps>(({ open, drawerWidth }) => ({
  overflowY: 'auto',
  marginLeft: open ? 'auto' : `-${drawerWidth}`,
  width: `100%`,
  display: 'flex',
  justifyContent: 'center',
  marginTop: '0px',
  zIndex: 1,
}));

const StyledAlert = styled(Alert)({
  marginBottom: '20px',
  marginTop: '15px',
  backgroundColor: '#DEDEDE',
  color: 'black',
  '.MuiAlert-icon': { display: 'flex', alignItems: 'center' },
});

const CardContainer = styled('div')({
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
  justifyContent: 'center',
  paddingBottom: '20px',
});

const StyledLink = styled('a')({
  color: 'black',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});

const SearchContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '30px',
  paddingBottom: '20px',
});

const SortDropdownContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '15px',
  width: '100%',
});

const StyledPagination = styled(Pagination)({
  paddingBottom: '20px',
  'button.Mui-selected': {
    backgroundColor: 'rgba(25, 118, 210, 0.1)',
  },
});

const INITIAL_TAG_DEFAULTS = Object.values(Tag).reduce((acc, tag) => {
  acc[tag] = 0;
  return acc;
}, {} as TagCounts);

const Prompts = ({ publishedOnly }: { publishedOnly: boolean }): JSX.Element => {
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [shouldFetchPrompts, setShouldFetchPrompts] = useState<boolean>(true);
  const [editOpen, setEditOpen] = useState(false);
  const { currentUser } = useAuth();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tagOptions, setTagOptions] = useState<TagCounts>(INITIAL_TAG_DEFAULTS);
  const [passedPrompt, setPassedPrompt] = useState<Prompt | null>(null);
  const [shouldFetchTags, setShouldFetchTags] = useState<boolean>(true);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width: 800px)').matches
  );
  const [sortOption, setSortOption] = useState<PromptSortOptions>(PromptSortOptions.DEFAULT);
  const [sortDirection, setSortDirection] = useState<SortDirection>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce(searchQuery);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const pageSize = 10;
  const features = useFeatures();

  const sortOptions = [
    { label: 'Default', value: PromptSortOptions.DEFAULT },
    { label: 'Likes', value: PromptSortOptions.LIKES },
    { label: 'Usage', value: PromptSortOptions.USAGE },
  ];

  const sortDirectionOptions = [
    { label: 'High to Low', value: SortDirection.DESC },
    { label: 'Low to High', value: SortDirection.ASC },
  ];

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;

      if (width < 1200) {
        setFilterDrawerOpen(false);
      } else {
        setFilterDrawerOpen(true);
      }
    }
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    setShouldFetchTags(true);
  }, [publishedOnly, debouncedSearchQuery, selectedTags]);

  useEffect(() => {
    setShouldFetchPrompts(true);
  }, [publishedOnly, page, sortOption, debouncedSearchQuery, sortDirection, selectedTags]);

  useEffect(() => {
    const promptId = searchParams.get('promptId');
    if (promptId) {
      const fetchPrompt = async () => {
        const prompt = await findPrompt(promptId);
        prompt.published = false;
        setPassedPrompt(prompt);
        setEditOpen(true);
      };
      fetchPrompt();
    }
  }, [searchParams]);

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchQuery, selectedTags, publishedOnly, sortDirection, sortOption]);

  useEffect(() => {
    const fetchPromptTags = async (
      publishedOnly: boolean,
      tags: string[],
      userId?: string,
      searchQuery?: string
    ) => {
      const promptTags = await findPromptTags(publishedOnly, tags, userId, searchQuery);
      const newTagOptions = { ...INITIAL_TAG_DEFAULTS };

      promptTags.forEach(({ tag, count }) => {
        newTagOptions[tag] = +count;
      });
      setTagOptions(newTagOptions);
      setShouldFetchTags(false);
    };

    if (shouldFetchTags) {
      fetchPromptTags(publishedOnly, selectedTags, currentUser?.id, debouncedSearchQuery);
    }
  }, [shouldFetchTags, publishedOnly, currentUser?.id, debouncedSearchQuery, selectedTags]);

  useEffect(() => {
    const fetchPrompts = async () => {
      const pageOffset = pageSize * (page - 1);
      const { data: newPrompts, totalCount } = await findPrompts({
        publishedOnly,
        sort: sortOption,
        pageOffset,
        userId: currentUser?.id,
        searchQuery: debouncedSearchQuery,
        sortDirection,
        tags: selectedTags,
      });
      setPrompts(newPrompts);
      setTotalPages(Math.ceil(totalCount / pageSize) ?? 0);
      setShouldFetchPrompts(false);
      setLoading(false);
    };
    if (shouldFetchPrompts) {
      fetchPrompts();
    }
  }, [
    currentUser?.id,
    publishedOnly,
    shouldFetchPrompts,
    page,
    sortOption,
    debouncedSearchQuery,
    sortDirection,
    selectedTags,
  ]);

  const handleTagClick = (event: React.ChangeEvent<HTMLInputElement>, tag: string) => {
    let newTags: string[] = [];
    if (event.target.checked) {
      newTags = [...selectedTags, tag];
    } else {
      newTags = selectedTags.filter((t) => t !== tag);
    }

    setSelectedTags(newTags);
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSortOption(e.target.value as PromptSortOptions);

    if (e.target.value === PromptSortOptions.DEFAULT) {
      setSortDirection(undefined);
    }
    if (!sortDirection && e.target.value !== PromptSortOptions.DEFAULT) {
      setSortDirection(SortDirection.DESC);
    }
  };

  const handleSortDirectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSortDirection(e.target.value as SortDirection);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (_e: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onSuccess = () => {
    setShouldFetchPrompts(true);
    setShouldFetchTags(true);
  };

  const drawerWidth = '350px';

  const paginator = (
    <StyledPagination
      count={totalPages}
      page={page}
      onChange={handlePageChange}
      size={'small'}
      sx={{ justifyContent: 'center', display: 'flex' }}
    />
  );

  return (
    <MainDiv>
      <DrawerContainer open={filterDrawerOpen} drawerWidth={drawerWidth}>
        <Drawer
          variant="persistent"
          anchor="left"
          open={filterDrawerOpen}
          sx={{ width: drawerWidth, height: '100%' }}
        >
          <PromptFilters tagOptions={tagOptions} handleTagUpdate={handleTagClick} />
        </Drawer>
      </DrawerContainer>
      <DrawerToggleButton
        isDrawerOpen={filterDrawerOpen}
        setIsDrawerOpen={setFilterDrawerOpen}
        tooltipCaption={filterDrawerOpen ? 'Close Filter Panel' : 'Open Filter Panel'}
      />
      <PromptsContainer open={filterDrawerOpen} drawerWidth={drawerWidth}>
        <Container maxWidth="md">
          <StyledAlert icon={`👋`} style={{ backgroundColor: '#8a8e96' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
              <span style={{ fontWeight: '700' }}>Pro tip:</span> if you want to learn more about
              prompts, check out this{' '}
              <StyledLink
                href={
                  'https://learn.microsoft.com/en-us/azure/ai-services/openai/concepts/prompt-engineering'
                }
                target="_blank"
              >
                <OpenInNew sx={{ verticalAlign: 'top', height: '18px' }} />
                <span style={{ verticalAlign: 'top' }}>Intro to Prompt Engineering</span>
              </StyledLink>
              . And if you really want to nerd out, see this{' '}
              <StyledLink href={'https://github.com/brexhq/prompt-engineering'} target="_blank">
                <OpenInNew sx={{ verticalAlign: 'top', height: '18px' }} />
                <span style={{ verticalAlign: 'top' }}>Prompt Engineering Guide</span>
              </StyledLink>
              .
            </Typography>
          </StyledAlert>
          <div>
            {!publishedOnly && (
              <Button
                sx={{ marginBottom: '20px', display: 'inline-block', backgroundColor: '#00b5e3' }}
                variant="contained"
                onClick={() => setEditOpen(true)}
              >
                Create Prompt
              </Button>
            )}
          </div>
          <SearchContainer>
            <TextField
              type={'search'}
              onChange={handleInputChange}
              value={searchQuery}
              label={'Search'}
              fullWidth
            />
            <SortDropdownContainer>
              <TextField value={sortOption} onChange={handleSortChange} label={'Sort'} select>
                {sortOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                value={sortDirection || ''}
                onChange={handleSortDirectionChange}
                label={'Sort Direction'}
                disabled={sortOption === PromptSortOptions.DEFAULT}
                select
                sx={{ minWidth: '35%' }}
              >
                {sortDirectionOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </SortDropdownContainer>
          </SearchContainer>
          {paginator}
          <PromptEdit
            isOpen={editOpen}
            setIsOpen={setEditOpen}
            onSuccess={onSuccess}
            prompt={passedPrompt || undefined}
            hideSave={passedPrompt ? true : false}
          />
          {loading ? (
            <Grid container columnSpacing={-10} rowSpacing={2} sx={{ width: '100% ' }}>
              {Array(6)
                .fill(null)
                .map((_, index) => (
                  <Grid key={index} item xs={12}>
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="101px"
                      animation="wave"
                      style={{
                        borderRadius: '20px',
                        backgroundColor: '#F3F6F4',
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <CardContainer>
              {prompts.filter(
                (prompt) => features.producer_playbook || prompt.appName !== 'PRODUCER_PLAYBOOK'
              ).length === 0 ? (
                <Typography>No prompts found for given filters</Typography>
              ) : (
                prompts
                  .filter(
                    (prompt) => features.producer_playbook || prompt.appName !== 'PRODUCER_PLAYBOOK'
                  )
                  .map((prompt) => {
                    if (prompt)
                      return (
                        <PromptCard
                          prompt={prompt}
                          key={prompt.id}
                          onPromptEdit={onSuccess}
                          publishedOnly={publishedOnly}
                        />
                      );
                  })
              )}
            </CardContainer>
          )}
          {paginator}
        </Container>
      </PromptsContainer>
    </MainDiv>
  );
};
export default Prompts;
