import { PromptSortOptions } from '../Types/enums';
import { CreatePrompt, FindPromptsArgs, Prompt, PromptAPIResponse } from '../Types/prompt';
import { TagCount } from '../Types/tags';
import { stringArrayToURLQueryParam } from '../util/stringUtils';
import { API_URL, enrichedFetch } from './api';

export const findPrompts = ({
  publishedOnly,
  sort,
  pageOffset = 0,
  userId,
  searchQuery,
  featuredOnly,
  pinned,
  liked,
  sortDirection,
  tags,
}: FindPromptsArgs): Promise<PromptAPIResponse> => {
  let url = `${API_URL}/prompts?publishedOnly=${publishedOnly}&sort=${sort}&offset=${pageOffset}`;

  if (featuredOnly) {
    url += `&featuredOnly=${featuredOnly}`;
  }
  if (userId) {
    url += `&userId=${userId}`;
  }
  if (pinned !== undefined) {
    url += `&pinned=${pinned}`;
  }
  if (liked !== undefined) {
    url += `&liked=${liked}`;
  }

  if (searchQuery) {
    url += `&q=${searchQuery}`;
  }

  if (sort !== PromptSortOptions.DEFAULT) {
    url += `&sortDirection=${sortDirection}`;
  }

  if (tags.length) {
    const tagsQuery = stringArrayToURLQueryParam('tags', tags);
    url += `&${tagsQuery}`;
  }

  return enrichedFetch(url) as Promise<PromptAPIResponse>;
};

export const findPrompt = (promptId: Prompt['id']): Promise<Prompt> => {
  return enrichedFetch(`${API_URL}/prompts/prompt/${promptId}`) as Promise<Prompt>;
};

export const createOrUpdatePrompt = (
  prompt: CreatePrompt,
  promptId?: Prompt['id']
): Promise<Prompt> => {
  return enrichedFetch(`${API_URL}/prompts/${promptId ?? ''}`, {
    method: promptId ? 'PUT' : 'POST',
    body: JSON.stringify(prompt),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Prompt>;
};

export const deletePrompt = async (promptId: Prompt['id']): Promise<void> => {
  await enrichedFetch(`${API_URL}/prompts/${promptId}`, {
    method: 'DELETE',
  });
};

export const findPromptTags = (
  publishedOnly: boolean,
  tags: string[],
  userId?: string,
  searchQuery?: string
): Promise<TagCount[]> => {
  let url = `${API_URL}/prompts/tags?publishedOnly=${publishedOnly}`;

  if (tags.length) {
    const tagsQuery = stringArrayToURLQueryParam('tags', tags);
    url += `&${tagsQuery}`;
  }

  if (userId) {
    url += `&userId=${userId}`;
  }

  if (searchQuery) {
    url += `&q=${searchQuery}`;
  }

  return enrichedFetch(url) as Promise<TagCount[]>;
};
