import styled from '@emotion/styled';
import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { ComponentPropsWithoutRef } from 'react';

import useCopyToClipboard from '../../hooks/useCopyToClipboard';

const CodeCopyButtonComponent = styled('span')({
  position: 'absolute',
  top: '-1px',
  right: '0',
  cursor: 'pointer',
  zIndex: 1,
});

type CopyCodeButtonProps = ComponentPropsWithoutRef<'button'> & {
  loading?: boolean;
};

export default function CodeCopyButton({ loading, children }: CopyCodeButtonProps) {
  const { copySuccess, copyToClipboard } = useCopyToClipboard();

  // this color is the green color of strings in the code blocks for uniformity
  const iconColor: string = copySuccess ? 'rgb(171, 227, 56)' : 'white';
  const codeText = (children as React.ReactElement[])[0].props.children[0];

  return (
    <CodeCopyButtonComponent>
      <IconButton
        disabled={loading}
        onClick={() => copyToClipboard(codeText)}
        sx={{
          color: iconColor,
          transition: 'all 0.2s ease-in-out',
          '&:disabled': { color: 'white' },
        }}
      >
        <ContentCopy sx={{ height: '1rem' }} />
      </IconButton>
    </CodeCopyButtonComponent>
  );
}
