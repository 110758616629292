import { useMsal } from '@azure/msal-react';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  getAzureGraphToken,
  login,
  loginAzureAD,
  loginToken,
  logoutToken,
  logoutUser,
} from '../../services/api';
import { User } from '../../Types/user';

interface AuthContextType {
  currentUser: User | null;
  isAdmin: boolean;
  authenticate: (email: string, password: string, redirectTo: string) => void;
  authenticateAzureAD: (redirectTo: string) => void;
  logout: (redirect?: string) => void;
  initialized: boolean;
}

const SpinnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

export const AuthContext = createContext<AuthContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

async function fetchProfilePhoto(email?: string) {
  //get user id from microsoft graph first using email and then use that id value to get their photo
  let userId = '';
  const graphToken = await getAzureGraphToken();

  if (email) {
    const userData = await fetch(`https://graph.microsoft.com/v1.0/users/${email}`, {
      headers: {
        Authorization: `Bearer ${graphToken}`,
      },
    });

    if (userData.status === 200) {
      const user = await userData.json();
      userId = user.id;
    }
  }

  const url = `https://graph.microsoft.com/v1.0/users/${userId}/photos/48x48/$value`;

  const profilePhotoData = await fetch(url, {
    headers: {
      Authorization: `Bearer ${graphToken}`,
    },
  });

  if (profilePhotoData.status === 200) {
    const photoData = await profilePhotoData.blob();
    localStorage.setItem('profilePhoto', URL.createObjectURL(photoData));
  } else {
    localStorage.setItem('profilePhoto', '');
  }
}

export const profilePhoto = localStorage.getItem('profilePhoto');

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [checkedForToken, setCheckedForToken] = useState(false);

  const logout = useCallback(
    async (redirectTo?: string) => {
      try {
        await logoutUser();
        await logoutToken();
        setCurrentUser(null);
        sessionStorage.clear();
        localStorage.clear();
        if (redirectTo) {
          navigate(redirectTo, { replace: true });
        }
      } catch (error) {
        setCurrentUser(null);
        enqueueSnackbar('Error logging out', { variant: 'error' });
      }
    },
    [navigate]
  );

  useEffect(() => {
    const tokenLogin = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');
      if (token) {
        localStorage.setItem('jwt', token);
        await loginToken();
        setCheckedForToken(true);
      } else {
        setCheckedForToken(true);
      }
    };
    tokenLogin();
  }, []);

  useEffect(() => {
    const checkLogin = async () => {
      try {
        let user = null;
        try {
          user = await login();
        } catch (error) {
          console.error(error);
        }
        if (user) {
          setCurrentUser(user);
          setIsAdmin(user.role === 'ADMIN');
          await fetchProfilePhoto(user.email);
        } else {
          setInitialized(false);
          await instance.initialize();
          const authResult = await instance.handleRedirectPromise();

          const token = await instance.acquireTokenSilent({
            scopes: [`api://${import.meta.env.VITE_AZUREAD_CLIENT_ID}/dev/app.dev`],
            account: instance.getAllAccounts()[0],
          });
          const graphToken = await instance.acquireTokenSilent({
            scopes: ['User.Read', 'GroupMember.Read.All'],
            account: instance.getAllAccounts()[0],
          });
          localStorage.setItem('token', token.accessToken);
          localStorage.setItem('graphToken', graphToken.accessToken);
          await fetchProfilePhoto();

          user = await loginAzureAD();
          setCurrentUser(user);
          setIsAdmin(user.role === 'ADMIN');
          if (authResult?.state) {
            window.location.href = authResult.state;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setInitialized(true);
      }
    };
    if (checkedForToken) {
      checkLogin();
    }

    // Including the navigate dep causes an infinite loop, but using react router to navigate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, checkedForToken]);

  const authenticate = async (email: string, password: string, redirectTo: string) => {
    const user = await login(email, password);
    setCurrentUser(user);
    setIsAdmin(user.role === 'ADMIN');
    navigate(redirectTo);
  };

  const authenticateAzureAD = async (redirectTo: string) => {
    await instance.loginRedirect({
      scopes: [`api://${import.meta.env.VITE_AZUREAD_CLIENT_ID}/dev/app.dev`],
      redirectUri: `${window.location.origin}/chat`,
      state: redirectTo,
    });
  };

  if (!initialized || loading) {
    return (
      <SpinnerContainer>
        <CircularProgress />
      </SpinnerContainer>
    );
  }

  return (
    <AuthContext.Provider
      value={{ currentUser, authenticate, authenticateAzureAD, logout, initialized, isAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return authContext;
};

export const RequireAuth: React.FC<Props> = ({ children }) => {
  const { currentUser, initialized } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (initialized && !currentUser) {
      let pathname = window.location.pathname;

      if (pathname.startsWith('/chat')) {
        pathname = '/chat';
      }
      navigate(`/login?redirect=${pathname}${location.search}`, { replace: true });
    }
  }, [currentUser, initialized, navigate, location]);

  if (!initialized) {
    return <CircularProgress />;
  }

  return <>{children}</>;
};
